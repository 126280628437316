import { ReportAction, ReportActionTypes } from '../actions';
import produce from 'immer';
import { Report } from '../../repos';

export interface ReportStateType {
  readonly loading: boolean;
  readonly report?: Report;
}

const initialState: ReportStateType = { loading: false };

export const ReportReducer = (
  currentState: ReportStateType = initialState,
  action: ReportAction,
) => {
  return produce(currentState, (draft) => {
    switch (action.type) {
      case ReportActionTypes.GetReport:
        draft.loading = true;
        draft.report = undefined;
        break;
      case ReportActionTypes.SetReport:
        draft.loading = false;
        draft.report = action.payload.report;
        break;
      default: break;
    }
    return draft;
  });
};
