import React, { HTMLAttributes } from 'react';

export const Line: React.SFC<HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <div
    {...props}
    style={{
      ...props.style,
      height: 1,
      backgroundColor: '#e9ebf1',
      alignSelf: 'stretch',
    }}/>
  );
};
