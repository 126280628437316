import { Observable, from } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

interface PartialResponse {
  responseJson: any;
  status: number;
}

export enum Method {
  Get = 'get',
  Post = 'post',
  Put = 'put',
}

export const request = (url: RequestInfo, options?: RequestInit): Observable<any> => {
  const defaultHeaders = { 'Content-Type': 'application/json' };
  const headers = options ? { ...options.headers,  ...defaultHeaders } : defaultHeaders;
  return from(fetch(url, { ...options, headers }))
    .pipe(
      switchMap((response: Response): Observable<PartialResponse> => {
        return from(response.json())
          .pipe(map(json => ({ responseJson: json, status: response.status })));
      }),
      map((partialResponse: PartialResponse) => {
        if (partialResponse.status === 200) {
          return partialResponse.responseJson;
        }
        if (partialResponse.responseJson.error) {
          throw partialResponse.responseJson.error;
        }
        throw new Error('Something went wrong');
      }));
};
