import { combineEpics, Epic } from 'redux-observable';
import { RootAction } from '../actions';
import { RootState } from '../reducers';

import {
  signInEpic,
  sendRecoveryEmailEpic,
  listenToAuthStateEpic,
  getEmployeeProfileEpic,
  listenToBusinessProfileEpic,
  listenToAssociatedEmployees,
  listenToAssociatedListings,
  logoutEpic,
} from './AuthEpic';

import {
  getUnhappyCustomerReviewsEpic,
  getAverageTurnaroundTimeEpic,
  getHappyCustomerReviewsEpic,
  getNeutralCustomerReviewsEpic,
} from './HomeEpics';

import {
  listenToReviewDetailsEpic,
  markReviewAsEpic,
  deleteReviewEpic,
  addReviewNoteEpic,
} from './ReviewDetailEpics';

import { getReportEpic } from './ReportEpic';

export type RootEpic = Epic<RootAction, RootAction, RootState>;

export default combineEpics(
  signInEpic,
  sendRecoveryEmailEpic,
  listenToAuthStateEpic,
  getEmployeeProfileEpic,
  listenToBusinessProfileEpic,
  listenToAssociatedEmployees,
  listenToAssociatedListings,
  logoutEpic,

  getUnhappyCustomerReviewsEpic,
  getHappyCustomerReviewsEpic,
  getNeutralCustomerReviewsEpic,
  getAverageTurnaroundTimeEpic,

  listenToReviewDetailsEpic,
  markReviewAsEpic,
  deleteReviewEpic,
  addReviewNoteEpic,

  getReportEpic,
);
