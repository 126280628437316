import { Observable, Observer, from, of } from 'rxjs';
import { firestore } from 'firebase';

export const listenToQuery = (
  ref: firestore.Query,
): Observable<firestore.QuerySnapshot> => {
  return Observable.create((observer: Observer<firestore.QuerySnapshot>) => {
    ref.onSnapshot(
      (snapshot) => {
        observer.next(snapshot);
      },
      (error) => {
        observer.error(error);
      },
    );
  });
};

export const listenToDocument = (
  ref: firestore.DocumentReference,
): Observable<firestore.DocumentSnapshot> => {
  return Observable.create((observer: Observer<firestore.DocumentSnapshot>) => {
    ref.onSnapshot(
      (snapshot) => {
        observer.next(snapshot);
      },
      (error) => {
        observer.error(error);
      },
    );
  });
};

export const getDocument = (
  ref: firestore.DocumentReference,
): Observable<firestore.DocumentSnapshot> => {
  return from(ref.get());
};

export const getQuery = (
  ref: firestore.Query,
): Observable<firestore.QuerySnapshot> => {
  return from(ref.get());
};

export const update = (
  ref: firestore.DocumentReference,
  data: any,
): Observable<boolean> => {
  ref.update(data);
  return of(true);
};
