import { SectionListData } from '../../utils';
import { ReviewNote } from '../../repos';
import { ReviewDetailAction, ReviewDetailActionTypes } from '../actions';
import produce from 'immer';

export interface ReviewDetailStateType {
  readonly reviewNotes?: SectionListData<ReviewNote>[];
  readonly loading: boolean;
}

const initialState: ReviewDetailStateType = { loading: false };

export const ReviewDetailReducer = (
  currentState: ReviewDetailStateType = initialState,
  action: ReviewDetailAction,
) => {
  return produce(currentState, (draft) => {
    switch (action.type) {
      case ReviewDetailActionTypes.GetReviewDetails:
        draft.loading = true;
        draft.reviewNotes = [];
        break;
      case ReviewDetailActionTypes.SetReviewDetails:
        draft.loading = false;
        draft.reviewNotes = action.payload.reviewNotes;
        break;
      default: break;
    }
    return draft;
  });
};
