import React from 'react';
import { Dots, SectionTitle, Line, Footer } from '../../../components';
import { BarChart, XAxis, YAxis, Bar, Cell, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { Colors } from '../../../theme';
import { capitalize } from 'lodash';
import { HomeProps } from '../../../repos';

export const Home: React.SFC<HomeProps> = props => {
  const {
    organizationName,
    dates: [, end],
    overall,
    nps,
    averageRating,
    totalRequests,
    totalRatings,
    unhappyCustomers,
  } = props;

  const graphData = Object.entries(overall).map(([name, value]) => ({
    value,
    name: capitalize(name),
    color: name === 'measure' ? Colors.Blue : name === 'act' ? Colors.Yellow : Colors.Red,
  }));

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
        alignItems: 'stretch',
        fontFamily: 'Lato',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 0.3,
          color: 'white',
          justifyContent: 'center',
          paddingLeft: 40,
          paddingRight: 40,
          background: 'linear-gradient(341deg, #2a88f1, #512dcf)',
        }}>
        <Dots />
        <div style={{ fontSize: 24, marginTop: 16 }}>{organizationName} CX Report</div>
        <div style={{ alignItems: 'center', marginTop: 16, display: 'flex', flexDirection: 'row' }}>
          <div style={{ fontWeight: 600, fontSize: 16 }}>Overall Report</div>
          <div
            style={{
              position: 'absolute',
              marginLeft: 80,
              fontSize: 48,
              fontWeight: 900,
              opacity: 0.15,
            }}>
            {moment(end).year()}
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 0.7,
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 72,
          paddingLeft: 48,
          paddingRight: 16,
          flexGrow: 1,
          alignItems: 'flex-start',
        }}>
        <SectionTitle title="Overview" section={1} />
        <div
          style={{
            fontSize: 18,
            marginTop: 24,
            marginBottom: 24,
            fontWeight: 700,
            color: Colors.TextDarker,
            textTransform: 'uppercase',
          }}>
          Overall business health
        </div>
        <ResponsiveContainer height={180} width={'100%'}>
          <BarChart
            margin={{ left: 16, right: 48 }}
            style={{ alignSelf: 'stretch' }}
            layout="vertical"
            barGap={40}
            data={graphData}>
            <YAxis tickLine={false} axisLine={false} type="category" dataKey="name" />
            <XAxis type="number" hide domain={[0, 100]} />
            <Bar
              isAnimationActive={false}
              // Doesn't work with radius in print for some reason
              // radius={[0, 8, 8, 0]}
              barSize={16}
              minPointSize={2}
              height={20}
              label={{
                position: 'right',
                fill: 'rgb(58, 75, 120)',
                fontSize: 15,
                fontWeight: 600,
                offset: 8,
                formatter: label => `${label}%`,
              }}
              dataKey="value">
              {graphData.map(item => {
                return <Cell key={item.name} fill={item.color} />;
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <Line style={{ marginTop: 48, marginBottom: 48, marginRight: 48 }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <div style={{ display: 'flex', flexDirection: 'column', color: Colors.Text }}>
            <div style={{ fontSize: 20 }}>{nps}</div>
            <div style={{ fontSize: 12, marginTop: 12, opacity: 0.5, fontWeight: 700 }}>NPS</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', color: Colors.Text }}>
            <div style={{ fontSize: 20 }}>{averageRating}</div>
            <div style={{ fontSize: 12, marginTop: 12, opacity: 0.5, fontWeight: 700 }}>
              Avg Rating
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', color: Colors.Text }}>
            <div style={{ fontSize: 20 }}>{totalRequests}</div>
            <div style={{ fontSize: 12, marginTop: 12, opacity: 0.5, fontWeight: 700 }}>
              Total Requests
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', color: Colors.Text }}>
            <div style={{ fontSize: 20 }}>{totalRatings}</div>
            <div style={{ fontSize: 12, marginTop: 12, opacity: 0.5, fontWeight: 700 }}>
              Total Ratings
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', color: Colors.Text }}>
            <div style={{ fontSize: 20 }}>{unhappyCustomers}</div>
            <div style={{ fontSize: 12, marginTop: 12, opacity: 0.5, fontWeight: 700 }}>
              Unhappy Customers
            </div>
          </div>
        </div>
        <Footer style={{ marginRight: 48, marginTop: 'auto' }} page={1} />
      </div>
    </div>
  );
};
