import * as React from 'react';
import { Icon } from 'antd';
import { Colors } from '../../theme';

interface ContainerLoadingProps {
  description?: string;
}

export const ContainerLoading: React.SFC<ContainerLoadingProps> = (props) => {
  return (
    <div
      style={{
        marginTop: 48,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center' }}>
        <Icon type="loading"/>
        <span style={{ marginTop: 12, color: Colors.Steel200 }}>
          {props.description || 'Loading'}
        </span>
      </div>
  );
};
