import React from 'react';
import {
  ReviewType,
  ResolutionMetadata,
  ResolutionStageType,
  EmployeeProfileType,
} from '../../repos';
import { Colors } from '../../theme';
import { Avatar, Icon, Button, Select } from 'antd';
import { RootState } from '../../redux';
import { connect } from 'react-redux';

const { Option } = Select;

interface ReviewCardProps {
  review: ReviewType;
  stages: ResolutionMetadata;
  employeeListings?: {
    [key: string]: string;
  };
  associatedEmployees?: {
    [key: string]: EmployeeProfileType;
  };
  showAddNotes?: (stage: ResolutionStageType) => void;
  showReviewDetails?: () => void;
}

const RatingView: React.SFC<{ rating: number }> = props => {
  const { rating } = props;

  const getColor = () => {
    if (rating <= 3) {
      return Colors.Red300;
    }
    if (rating <= 4) {
      return Colors.Brown400;
    }
    return Colors.Emerald400;
  };

  const getIconName = () => {
    if (rating <= 4) {
      return 'star-o';
    }
    return 'star';
  };

  return (
    <Avatar
      style={{
        backgroundColor: getColor(),
      }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div
          style={{
            color: Colors.White100,
          }}>
          {rating}
        </div>
        <Icon
          style={{
            fontSize: 10,
            marginLeft: 2,
            color: Colors.White100,
          }}
          type={getIconName()}
        />
      </div>
    </Avatar>
  );
};

// tslint:disable-next-line: variable-name
const _ReviewCard: React.SFC<ReviewCardProps> = props => {
  const {
    rating,
    reviewerName,
    reviewerMobileNumber,
    updatedAt,
    createdAt,
    reviewedAt,
    comment,
    listingUid,
    notesCount,
    resolved,
    employeeUid,
    resolutionStatus,
  } = props.review;
  const { showAddNotes, showReviewDetails, stages, employeeListings, associatedEmployees } = props;

  const selectedResolutionStage = resolved
    ? stages.stages.find(stage => stage.isResolvedStage)!.name
    : (
        (resolutionStatus && stages.stages.find(stage => resolutionStatus === stage.name)) ||
        stages.initialStage
      ).id;

  const markReview = (id: string) => {
    showAddNotes && showAddNotes(stages.stages.find(stage => stage.id === id)!);
  };

  return (
    <div
      style={{
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: Colors.White200,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 16,
        paddingTop: 16,
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          marginTop: 8,
        }}>
        <RatingView rating={rating || 0} />
        <div
          style={{
            color: Colors.Steel500,
            fontSize: 15,
            marginLeft: 16,
          }}>
          {reviewerName}
        </div>
        <Icon style={{ marginLeft: 16, color: Colors.Steel300 }} type="phone" />
        <div
          style={{
            color: Colors.Steel300,
            fontSize: 12,
            marginLeft: 4,
          }}>
          {reviewerMobileNumber}
        </div>
        {employeeListings && (
          <Icon style={{ marginLeft: 16, color: Colors.Steel300 }} type="environment" />
        )}
        {employeeListings && (
          <div
            style={{
              color: Colors.Steel300,
              fontSize: 12,
              marginLeft: 4,
            }}>
            {employeeListings[listingUid]}
          </div>
        )}
        {associatedEmployees && (
          <Icon style={{ marginLeft: 16, color: Colors.Steel300 }} type="user" />
        )}
        {associatedEmployees && (
          <div
            style={{
              color: Colors.Steel300,
              fontSize: 12,
              marginLeft: 4,
            }}>
            {associatedEmployees[employeeUid].name}
          </div>
        )}
        <div
          style={{
            color: Colors.White800,
            marginLeft: 'auto',
          }}>
          Reviewed At → {reviewedAt ? reviewedAt.format('h:mm a') : updatedAt.format('h:mm a')}
          <br />
          Requested
          {createdAt.isSame(reviewedAt, 'day')
            ? ' At → ' + createdAt.format('h:mm a')
            : ' On → ' + createdAt.format('Do MMM, h:mm a')}
          <br />
        </div>
      </div>
      {comment && (
        <div
          style={{
            marginTop: 16,
            marginBottom: 8,
            fontSize: 14,
            backgroundColor: Colors.Grey200,
            borderRadius: 3,
            padding: '8px',
          }}>
          {typeof comment === 'string' ? comment : JSON.stringify(comment)}
        </div>
      )}
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          marginTop: 16,
        }}>
        {showReviewDetails && (
          <Button
            onClick={showReviewDetails}
            style={{
              border: 'none',
              paddingLeft: 0,
            }}
            icon="file-text">{`Remarks ${notesCount ? `(${notesCount})` : ''}`}</Button>
        )}
        {(rating || 0) <= 3 && (
          <Select
            size="small"
            defaultValue={selectedResolutionStage}
            onChange={markReview}
            style={{
              width: 150,
              border: 'none',
              alignContent: 'flex-start',
            }}>
            {stages.stages.map(option => (
              <Option value={option.id}>{option.name}</Option>
            ))}
          </Select>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { employeeListings, associatedEmployees } = state.BusinessProfile;
  return { employeeListings, associatedEmployees };
};

export const ReviewCard = connect(
  mapStateToProps,
  {},
)(_ReviewCard);
