import {
  CommonAction,
  CommonActionTypes,
} from '../actions';
import { produce } from 'immer';

export interface CommonStateType {
  readonly error?: string;
  readonly message?: string;
}

const initialState: CommonStateType = {};

export const CommonReducer = (
  currentState: CommonStateType = initialState,
  action: CommonAction,
  ) => {
  return produce(currentState, (draft) => {
    draft.error = undefined;
    draft.message = undefined;
    switch (action.type) {
      case CommonActionTypes.SetError:
        draft.error = action.payload.error;
        break;
      case CommonActionTypes.SetMessage:
        draft.message = action.payload.message;
        break;
      default: break;
    }
    return draft;
  });
};
