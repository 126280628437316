export const generateUuid = () => {
  let uuid = '';
  let i;
  let random;
  for (i = 0; i < 32; i += 1) {
    random = (Math.random() * 16) | 0;

    uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
  }
  return uuid;
};

export const toTitleCase = (camelCase: string): string => {
  return camelCase
    .replace(/([A-Z])/g, (match: string) => ` ${match}`)
    .replace(/^./, (match: string) => match.toUpperCase());
};

export const validateMobileNumber = (mobileNumber: string): boolean => {
  if (mobileNumber.length !== 13) {
    return false;
  }
  const regExString = '(\\+91)[156789][0-9]{9}';
  const indianMobileNumberRegExp = new RegExp(regExString, 'g');
  return indianMobileNumberRegExp.test(mobileNumber);
};

export const containsSpecialCharacters = (input: string): boolean => {
  const regExString = '^[a-zA-Z0-9]*$';
  const inputTest = new RegExp(regExString, 'g');
  const isValid = inputTest.test(input);
  return !isValid;
};

export const isValidEmail = (email: string) => {
// tslint:disable-next-line: max-line-length
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
