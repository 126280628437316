import { Observable, from, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { auth, User } from 'firebase';

export type AuthStateResult = User | null;

export const signIn = (emailAddress: string, password: string): Observable<auth.UserCredential> => {
  return from(auth().signInWithEmailAndPassword(emailAddress, password)).pipe(
    map((currentUser: auth.UserCredential) => currentUser),
  );
};

export const sendRecoveryEmail = (emailAddress: string): Observable<void> => {
  return from(auth().sendPasswordResetEmail(emailAddress));
};

export const listenToAuthState = (): Observable<AuthStateResult> => {
  return Observable.create((observer: Observer<AuthStateResult>) => {
    auth().onAuthStateChanged(() => {
      return observer.next(auth().currentUser);
    });
  });
};

export const logout = (): Observable<void> => {
  return from(auth().signOut());
};
