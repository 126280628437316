import { action, ActionType } from 'typesafe-actions';
import { ResolutionStageType } from '../../repos';
import { ReviewNote } from '../../repos/Review';
import { SectionListData } from '../../utils';

export enum ReviewDetailActionTypes {
  GetReviewDetails = 'ReviewDetails/GetReviewDetails',
  SetReviewDetails = 'ReviewDetails/SetReviewDetails',
  MarkReviewAs = 'ReviewDetails/MarkReviewAs',
  AddNoteToReview = 'ReviewDetails/AddNoteToReview',
  DeleteReview = 'ReviewDetails/DeleteReview',
  SetMessage = 'ReviewDetails/SetMessage',
  SetError = 'ReviewDetails/SetError',
  Unsubscribe = 'ReviewDetails/Unsubscribe',
}

export const getReviewDetails = (reviewUid: string) =>
  action(ReviewDetailActionTypes.GetReviewDetails, { reviewUid });

export const setReviewDetails = (reviewNotes: SectionListData<ReviewNote>[]) =>
  action(ReviewDetailActionTypes.SetReviewDetails, { reviewNotes });

export const markReviewAs = (reviewUid: string, stage: ResolutionStageType) =>
  action(ReviewDetailActionTypes.MarkReviewAs, { reviewUid, stage });

export const addNoteToReview = (reviewUid: string, description: string) =>
  action(ReviewDetailActionTypes.AddNoteToReview, { reviewUid, description });

export const setReviewDetailsError = (error?: string) =>
  action(ReviewDetailActionTypes.SetError, { error });

export const setReviewDetailsMessage = (message?: string) =>
  action(ReviewDetailActionTypes.SetMessage, { message });

export const deleteReview = (reviewUid: string) =>
  action(ReviewDetailActionTypes.DeleteReview, { reviewUid });

export const unsubscribeFromReviewDetails = () => action(ReviewDetailActionTypes.Unsubscribe);

const ReviewDetailActions = {
  getReviewDetails,
  setReviewDetails,
  markReviewAs,
  addNoteToReview,
  deleteReview,
  setReviewDetailsError,
  setReviewDetailsMessage,
  unsubscribeFromReviewDetails,
};

export type ReviewDetailAction = ActionType<typeof ReviewDetailActions>;
