import React from 'react';
import { Dots, SectionTitle, Footer } from '../../../components';
import { Colors } from '../../../theme';
import { TextCommentProps } from '../../../repos';

export const TextComments: React.SFC<TextCommentProps> = props => {
  const { comments } = props;
  return (
    <div
      style={{
        flexDirection: 'column',
        display: 'flex',
        padding: '24px 24px',
        fontFamily: 'Lato',
      }}>
      <Dots dotscolor={Colors.TextDarker} />
      <div
        style={{ flexDirection: 'column', display: 'flex', width: '100%', padding: '24px 24px' }}>
        <SectionTitle style={{ marginTop: 24 }} section={7} title="Text Comments" />
        <div
          style={{
            fontSize: 18,
            marginTop: 24,
            marginBottom: 24,
            fontWeight: 700,
            color: Colors.TextDarker,
            textTransform: 'uppercase',
          }}>
          Text Comments : {comments.length}
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto',
            gridTemplateRows: 'auto',
            columnGap: 8,
            gridRowGap: 16,
          }}>
          <div style={{ fontSize: 14, fontWeight: 700, color: Colors.Text, opacity: 0.6 }}>
            Customer Name
          </div>
          <div style={{ fontSize: 14, fontWeight: 700, color: Colors.Text, opacity: 0.6 }}>
            Phone Number
          </div>
          <div style={{ fontSize: 14, fontWeight: 700, color: Colors.Text, opacity: 0.6 }}>
            Branch
          </div>
          <div style={{ fontSize: 14, fontWeight: 700, color: Colors.Text, opacity: 0.6 }}>
            Employee
          </div>
          <div style={{ fontSize: 14, fontWeight: 700, color: Colors.Text, opacity: 0.6 }}>
            Comments
          </div>
          {comments
            .map((item, parentIndex) =>
              Object.entries(item).map(([key, item], index) => {
                return (
                  <div
                    key={`${key}${parentIndex}${index}`}
                    style={{
                      gridColumn: index + 1,
                      fontSize: 13,
                      color: Colors.Text,
                    }}>
                    {item}
                  </div>
                );
              }),
            )
            .reduce((acc, item) => acc.concat(item), [])}
        </div>
      </div>
      <Footer
        style={{
          marginTop: 'auto',
          marginLeft: 24,
          marginRight: -24,
        }}
        page={5}
      />
    </div>
  );
};
