import { map } from 'rxjs/operators';
import { RxFirestore } from '../Common';
import { Observable } from 'rxjs';
import { firestore } from 'firebase';
import { Moment } from 'moment-timezone';
import { mergeWith } from 'lodash';
import { FirestoreReference } from '../../utils';

export enum StatType {
  aggregate = 'aggregate',
  yearly = 'yearly',
  monthly = 'monthly',
  daily = 'daily',
  weekly = 'weekly',
}

export interface AverageTurnaroundTime {
  averageResponseTime: number;
  averageResolutionTime: number;
}

export const listenToAverageTurnaroundTime = (
  businessUid: string,
  listingUid: string,
  startDate: Moment,
  endDate: Moment,
): Observable<AverageTurnaroundTime> => {
  let statsRef = FirestoreReference.Stats()
    .doc(businessUid)
    .collection('ratings')
    .where(
      'date',
      '>=',
      startDate
        .tz('Atlantic/Reykjavik')
        .startOf('day')
        .toDate(),
    )
    .where(
      'date',
      '<=',
      endDate
        .tz('Atlantic/Reykjavik')
        .endOf('day')
        .toDate(),
    )
    .where('type', '==', StatType.daily);

  // TODO: Vamsee Chamakura 19/04/19 - Remove this conditional by making listingUid optional
  if (listingUid !== 'allListings') {
    statsRef = statsRef.where('listingUid', '==', listingUid);
  }
  return RxFirestore.listenToQuery(statsRef).pipe(
    map(querySnapshot => getAverageTurnaroundTime(querySnapshot)),
  );
};

const getAverageTurnaroundTime = (
  querySnapshot: firestore.QuerySnapshot,
): AverageTurnaroundTime => {
  const averageTurnaroundTime: AverageTurnaroundTime = {
    averageResolutionTime: -1,
    averageResponseTime: -1,
  };
  const mergedDoc = mergeStatDocuments(querySnapshot.docs);
  const { resolvedMetadata, responseMetadata } = mergedDoc;
  if (resolvedMetadata && resolvedMetadata.totalTime && resolvedMetadata.count) {
    averageTurnaroundTime.averageResolutionTime =
      resolvedMetadata.totalTime / resolvedMetadata.count;
  }
  if (responseMetadata && responseMetadata.totalTime && responseMetadata.count) {
    averageTurnaroundTime.averageResponseTime = responseMetadata.totalTime / responseMetadata.count;
  }
  return averageTurnaroundTime;
};

const mergeStatDocuments = (
  documents: firestore.DocumentSnapshot[],
): {
  sum: number;
  count: number;
  perRatingCount: { [key: string]: number };
  resolvedMetadata: { count: number; totalTime: number };
  responseMetadata: { count: number; totalTime: number };
} => {
  const initialValue = {
    sum: 0,
    count: 0,
    perRatingCount: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    },
    resolvedMetadata: {
      count: 0,
      totalTime: 0,
    },
    responseMetadata: {
      count: 0,
      totalTime: 0,
    },
  };
  const mergedObject = documents.reduce(
    (acc: typeof initialValue, item: firestore.DocumentSnapshot): typeof initialValue => {
      const document = item.data() as any;
      const { sum, count, perRatingCount, resolvedMetadata, responseMetadata } = document;
      acc.perRatingCount = mergeWith(
        acc.perRatingCount,
        perRatingCount,
        (v1, v2) => (v1 || 0) + (v2 || 0),
      );
      acc.sum += sum;
      acc.count += count;
      acc.resolvedMetadata = mergeWith(
        acc.resolvedMetadata,
        resolvedMetadata,
        (v1, v2) => (v1 || 0) + (v2 || 0),
      );
      acc.responseMetadata = mergeWith(
        acc.responseMetadata,
        responseMetadata,
        (v1, v2) => (v1 || 0) + (v2 || 0),
      );
      return acc;
    },
    initialValue,
  );
  return mergedObject;
};
