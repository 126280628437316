import { Moment } from 'moment';
import { action, ActionType } from 'typesafe-actions';
import { Report } from '../../repos';

export enum ReportActionTypes {
  GetReport = 'ReportAction/GetReport',
  SetReport = 'ReportAction/SetReport',
}

export const getReport = (startDate: Moment, endDate: Moment) =>
  action(ReportActionTypes.GetReport, { startDate, endDate });

export const setReport = (report: Report) =>
  action(ReportActionTypes.SetReport, { report });

const reportActions = {
  getReport,
  setReport,
};

export type ReportAction = ActionType<typeof reportActions>;
