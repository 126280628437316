import { ReviewType } from '../../repos';
import { SectionListData } from '../../utils';
import { produce } from 'immer';
import { HomeAction, HomeActionTypes } from '../actions';

export interface HomeStateType {
  readonly unhappyCustomerReviews?: {
    resolved: SectionListData<ReviewType>[];
    unresolved: SectionListData<ReviewType>[];
  };
  readonly happyCustomerReviews?: SectionListData<ReviewType>[];
  readonly neutralCustomerReviews?: SectionListData<ReviewType>[];
  averageTurnaroundTime?: {
    averageResponseTime: number;
    averageResolutionTime: number;
  };
  loading: boolean;
}

const initialState: HomeStateType = { loading: false };

export const HomeReducer = (currentState: HomeStateType = initialState, action: HomeAction) => {
  return produce(currentState, draft => {
    switch (action.type) {
      case HomeActionTypes.GetHappyCustomerReviews:
      case HomeActionTypes.GetNeutralCustomerReviews:
      case HomeActionTypes.GetUnhappyCustomerReviews:
        draft.loading = true;
        break;
      case HomeActionTypes.SetHappyCustomerReviews:
        draft.loading = false;
        draft.happyCustomerReviews = action.payload.reviews;
        break;
      case HomeActionTypes.SetNeutralCustomerReviews:
        draft.loading = false;
        draft.neutralCustomerReviews = action.payload.reviews;
        break;
      case HomeActionTypes.SetUnhappyCustomerReviews:
        draft.loading = false;
        draft.unhappyCustomerReviews = action.payload.reviews;
        break;
      case HomeActionTypes.SetAverageTurnaroundTime:
        draft.averageTurnaroundTime = action.payload.averageTurnaroundTime;
        break;
      default:
        break;
    }
    return draft;
  });
};
