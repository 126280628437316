import { mapTo, map } from 'rxjs/operators';
import { RxFirestore } from '../Common';
import { Observable, from } from 'rxjs';
import { generateUuid } from '../../utils/StringUtils';
import { firestore } from 'firebase';
import { FirestoreReference } from '../../utils';

export interface EmployeeProfileType {
  associatedListings: { [key: string]: string[] };
  mobileNumber: string;
  name: string;
  employeeUid: string;
  featureOptIn: {
    enableCallToPatient: boolean;
    showNps: boolean;
    requestReviewForOtherEmployees: boolean;
    enableScreens: {
      home: boolean;
      requestReview: boolean;
    };
    deleteReview?: boolean;
    showReportsInReputationWeb?: boolean;
  };
}

export interface ResolutionStageType {
  id: string;
  name: string;
  isResolvedStage: boolean;
}

export interface ResolutionMetadata {
  stages: ResolutionStageType[];
  initialStage: ResolutionStageType;
}

export interface BusinessProfileType {
  businessUid: string;
  businessName: string;
  ownerMobileNumber: string;
  ownerName: string;
  resolutionStages: ResolutionMetadata;
  listings: {
    [key: string]: {
      listingName: string;
      enabled: boolean;
      businessCode?: string;
      onlineProfiles?: { [key: string]: string };
      reviewUrls: {
        [key: string]: {
          order: number;
          url: string;
        };
      };
    };
  };
  skippableRemarks: boolean;
  associatedFlows: { [flowUid: string]: string };
  employees: string[];
}

const createBusinessProfile = (
  businessProfileDoc: firestore.DocumentSnapshot,
): BusinessProfileType => {
  const businessProfile = businessProfileDoc.data() as any;
  if (!businessProfileDoc.id) {
    throw new Error("createBusinessProfile - documentSnapshot doesn't have id");
  }
  const {
    name: businessName = '',
    ownerMobileNumber = '',
    ownerName = '',
    resolutionStages = {
      stages: [
        { name: 'Unresolved', isResolvedStage: false, id: 'unresolved' },
        { name: 'Resolved', isResolvedStage: true, id: 'resolved' },
      ],
      initialStage: { name: 'Unresolved', isResolvedStage: false, id: 'unresolved' },
    },
    listings = {},
    employees = [],
    skippableRemarks = true,
    associatedFlows,
  } = businessProfile;
  const businessProfileData = {
    associatedFlows,
    businessName,
    ownerMobileNumber,
    resolutionStages,
    ownerName,
    listings,
    employees,
    skippableRemarks,
    businessUid: businessProfileDoc.id,
  };
  return businessProfileData;
};

const createEmployeeProfile = (
  employeeProfileDoc: firestore.DocumentSnapshot,
): EmployeeProfileType => {
  if (employeeProfileDoc.id) {
    const employeeProfile = employeeProfileDoc.data() as any;
    const {
      associatedListings = {},
      mobileNumber = '',
      name = '',
      featureOptIn = {
        showNps: true,
        enableCallToPatient: true,
        requestReviewForOtherEmployees: false,
        enableScreens: {
          home: true,
          requestReview: true,
        },
        deleteReview: false,
        showReportsInReputationWeb: false,
      },
    }: Partial<EmployeeProfileType> = employeeProfile;

    const {
      showNps = true,
      enableCallToPatient = true,
      requestReviewForOtherEmployees = false,
      showReportsInReputationWeb = false,
      deleteReview = false,
    } = featureOptIn;
    const { home = true, requestReview = true } = featureOptIn.enableScreens
      ? featureOptIn.enableScreens
      : {};
    const employeeProfileData = {
      mobileNumber,
      associatedListings,
      name,
      featureOptIn: {
        showNps,
        enableCallToPatient,
        requestReviewForOtherEmployees,
        showReportsInReputationWeb,
        deleteReview,
        enableScreens: {
          home,
          requestReview,
        },
      },
      employeeUid: employeeProfileDoc.id,
    };
    return employeeProfileData;
  }
  throw new Error("Document Doesn't exist");
};

export const setBusinessProfile = (
  businessProfile: Partial<BusinessProfileType>,
  currentUserUid: string,
): Observable<boolean> => {
  const { businessName, ownerMobileNumber, ownerName } = businessProfile;
  const businessProfileDocRef = FirestoreReference.BusinessProfiles().doc();
  const businessProfileDocId = businessProfileDocRef.id;
  const businessEmployeesDocRef = FirestoreReference.BusinessEmployees().doc(currentUserUid);

  if (!businessProfileDocId) {
    throw new Error('Something Went Wrong!');
  }

  const listingUid = generateUuid();

  const businessProfileData = {
    ownerMobileNumber,
    ownerName,
    createdAt: firestore.FieldValue.serverTimestamp(),
    updatedAt: firestore.FieldValue.serverTimestamp(),
    name: businessName,
    listings: {
      [listingUid]: {
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp(),
        businessCode: '',
        enabled: false,
        name: businessName,
        onlineProfileIds: {
          google: '',
        },
        reviewUrls: {
          google: '',
        },
      },
    },
  };

  const businessEmployeeProfile = {
    createdAt: firestore.FieldValue.serverTimestamp(),
    updatedAt: firestore.FieldValue.serverTimestamp(),
    mobileNumber: ownerMobileNumber,
    name: ownerName,
    [`associatedListings.${businessProfileDocId}`]: firestore.FieldValue.arrayUnion(listingUid),
  };

  const batch = firestore().batch();
  batch.set(businessProfileDocRef, businessProfileData);
  batch.set(businessEmployeesDocRef, businessEmployeeProfile);

  return from(batch.commit()).pipe(mapTo(true));
};

export const listenToBusinessProfile = (businessUid: string): Observable<BusinessProfileType> => {
  return RxFirestore.listenToDocument(FirestoreReference.BusinessProfiles().doc(businessUid)).pipe(
    map(doc => createBusinessProfile(doc)),
  );
};

export const listenToEmployeeProfile = (
  currentUserUid: string,
): Observable<EmployeeProfileType> => {
  return RxFirestore.listenToDocument(
    FirestoreReference.BusinessEmployees().doc(currentUserUid),
  ).pipe(map(doc => createEmployeeProfile(doc)));
};
