import {
  BusinessProfileAction,
  BusinessProfileActionTypes,
} from '../actions';
import { produce } from 'immer';
import { BusinessProfileType, EmployeeProfileType } from '../../repos';

export interface BusinessProfileStateType {
  readonly currentListingUid?: string;
  readonly loading: boolean;
  readonly businessProfile?: BusinessProfileType;
  readonly employeeProfile?: EmployeeProfileType;
  readonly employeeListings?: {
    [key: string]: string;
  };
  readonly associatedEmployees?: {
    [key: string]: EmployeeProfileType;
  };
}

const initialState: BusinessProfileStateType = { loading: false };

export const BusinessProfileReducer = (
  currentState: BusinessProfileStateType = initialState, action: BusinessProfileAction) => {
  return produce(currentState, (draft) => {
    draft.loading = false;
    switch (action.type) {
      case BusinessProfileActionTypes.SetBusinessProfile:
        draft.businessProfile = action.payload.businessProfile;
        break;
      case BusinessProfileActionTypes.SetEmployeeProfile:
        draft.employeeProfile = action.payload.employeeProfile;
        break;
      case BusinessProfileActionTypes.SetCurrentListingUid:
        draft.currentListingUid = action.payload.currentListingUid;
        break;
      case BusinessProfileActionTypes.SetAssignedListings:
        draft.employeeListings = action.payload.employeeListings;
        break;
      case BusinessProfileActionTypes.SetAssociatedEmployees:
        draft.associatedEmployees = action.payload.associatedEmployees;
        break;
      default: break;
    }
    return draft;
  });
};
