import { RouteComponentProps } from '@reach/router';
import { Button, DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  AverageRating,
  EmployeeLeaderboard,
  Home,
  LocationLeaderboard,
  // MeasureAndAct,
  NPS,
  TextComments,
} from '.';
import { ContainerLoading } from '../../components';
import { getReport, RootState } from '../../redux';
import { Report } from '../../repos';
import { Colors } from '../../theme';
import { NPSAndAverageRating } from './NPSAndAverageRating';

export interface ReportProps {
  report?: Report;
  loading?: boolean;
  getReport: typeof getReport;
}

const Reports: React.SFC<RouteComponentProps<ReportProps>> = props => {
  const { report, loading, getReport } = props;

  // prettier-ignore
  const [selectedDates = [
    moment().startOf('isoWeek'), moment().add(1,'day').startOf('day')],
    setSelectedDates,
  ] = useState<[Moment, Moment] | undefined>();

  useEffect(() => {
    const [startDate, endDate] = selectedDates;
    getReport!(startDate, endDate);
  }, []);

  const renderReport = () => {
    if (!report || loading) {
      return <ContainerLoading />;
    }

    const {
      home,
      // measureAndAct,
      nps,
      averageRating,
      textComments,
      locationLeaderboard,
      employeeLeaderboard,
    } = report;

    return (
      <div
        style={{
          borderTopWidth: 1,
          borderTopColor: Colors.White400,
          borderTopStyle: 'solid',
          width: '100%',
        }}>
        <Home {...home} />
        {/* <MeasureAndAct {...measureAndAct} /> */}
        {nps.weeklyTrend.length !== 0 && averageRating.weeklyTrend.length !== 0 ? (
          <div>
            <NPS {...nps} />
            <AverageRating {...averageRating} />
          </div>
        ) : (
          <NPSAndAverageRating breakdown={nps.breakdown} ratingData={averageRating.ratingData} />
        )}
        <LocationLeaderboard {...locationLeaderboard} />
        <EmployeeLeaderboard {...employeeLeaderboard} />
        {textComments.comments.length !== 0 && <TextComments {...textComments} />}
      </div>
    );
  };

  return (
    <div
      style={{ width: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div
        style={{
          maxWidth: 1024,
          marginTop: 16,
          marginBottom: 16,
          display: 'flex',
          flex: 1,
          width: '100%',
          padding: '0 16px',
          justifyContent: 'center',
        }}>
        <DatePicker.RangePicker
          allowClear={false}
          separator="to"
          onChange={dates => {
            const [startDate, endDate] = dates as [Moment, Moment];
            getReport!(
              startDate.startOf('day'),
              endDate
                .clone()
                .add(1, 'day')
                .startOf('day'),
            );
            setSelectedDates([startDate.startOf('day'), endDate.startOf('day')]);
          }}
          defaultValue={selectedDates as [Moment, Moment]}
        />
        <Button
          onClick={() => window.print()}
          icon="cloud-download"
          type="primary"
          style={{ marginLeft: 'auto' }}>
          Download
        </Button>
      </div>
      {renderReport()}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { report, loading } = state.Report;
  return { report, loading };
};

export default connect(
  mapStateToProps,
  { getReport },
)(Reports);
