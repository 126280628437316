import { map, mapTo, switchMap } from 'rxjs/operators';
import { RxFirestore } from '../Common';
import { of, Observable, from } from 'rxjs';
import { firestore } from 'firebase';
import moment, { Moment } from 'moment-timezone';
import { last, unionBy, isEmpty } from 'lodash';
import { PaginationList, SectionListData, FirestoreReference } from '../../utils';
import { ResolutionStageType } from '../BusinessProfile';
import { Routes } from '../../App';

export interface ReviewType {
  businessUid: string;
  listingUid: string;
  employeeUid: string;
  flowUid?: string;
  createdAt: moment.Moment;
  comment: string | null;
  rating: number | null;
  uid: string;
  reviewStatus: ReviewStatus;
  reviewerName: string;
  reviewerMobileNumber: string;
  updatedAt: moment.Moment;
  triggerAt?: moment.Moment;
  reviewedAt?: moment.Moment;
  userResponses?: {
    [k: string]: {
      answerDisplayName: string;
      rawValue: string;
      value: string;
    };
  };
  origin: ReviewOrigin;
  employeeResponse?: {
    employeeUid: string;
    responseType: ResponseType;
    respondedAt: moment.Moment;
  };
  resolved: boolean;
  resolutionStatus?: string;
  resolvedBy?: {
    id: string;
    name: string;
  };
  lastNote?: ReviewNote;
  notesCount?: number;
}

export interface RatingsInsightsType {
  totalRatings: number;
  commentsReceived: number;
  callbacksMade: number;
  toCallBackReviews: SectionListData<ReviewType>[];
  commentedOnReviews: SectionListData<ReviewType>[];
  averageResponseTime: number;
}

export enum DeepLinkUrlType {
  dailyReviews = 'dailyReviews',
}

export enum ResponseType {
  call = 'call',
  message = 'message',
}

export enum ReviewStatus {
  notRequested = 'notRequested',
  requested = 'requested',
  reviewed = 'reviewed',
}

export enum ReviewOrigin {
  emr = 'emr',
  reputationApp = 'reputationApp',
  script = 'script',
}

export enum ReviewNoteType {
  System,
  User,
}

export interface ReviewNote {
  id: string;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  description: string;
  employeeUid: string;
  employeeName: string;
  type: ReviewNoteType;
}

const createReviewNote = (reviewNoteSnapshot: firestore.DocumentSnapshot): ReviewNote => {
  const data = reviewNoteSnapshot.data() as ReviewNote;
  return {
    ...data,
    id: reviewNoteSnapshot.id!,
    createdAt: isEmpty(reviewNoteSnapshot.get('createdAt'))
      ? moment()
      : moment(reviewNoteSnapshot.get('createdAt').toDate()),
    updatedAt: isEmpty(reviewNoteSnapshot.get('updatedAt'))
      ? moment()
      : moment(reviewNoteSnapshot.get('updatedAt').toDate()),
  };
};

const createReview = (reviewSnapshot: firestore.DocumentSnapshot): ReviewType => {
  const review = reviewSnapshot.data() as any;
  if (!reviewSnapshot.id) {
    throw new Error("createReview - documentSnapshot doesn't have id");
  }
  let { employeeResponse } = review;
  if (employeeResponse) {
    const { employeeUid, responseType, respondedAt } = employeeResponse;
    employeeResponse = {
      employeeUid,
      responseType,
      respondedAt: reviewSnapshot.get('employeeResponse.respondedAt')
        ? moment(respondedAt.toDate())
        : moment(),
    };
  }
  const {
    businessUid,
    listingUid,
    employeeUid,
    createdAt,
    comment,
    rating,
    flowUid,
    reviewStatus,
    reviewerName,
    reviewerMobileNumber,
    updatedAt,
    triggerAt,
    reviewedAt,
    origin,
    resolved = false,
    resolutionStatus,
    resolvedBy,
    lastNote,
    notesCount,
    userResponses,
  } = review;
  const reviewData = {
    businessUid,
    listingUid,
    employeeUid,
    comment,
    flowUid,
    rating,
    reviewStatus,
    reviewerName,
    reviewerMobileNumber,
    resolved,
    resolutionStatus,
    resolvedBy,
    lastNote,
    notesCount,
    userResponses,
    uid: reviewSnapshot.id,
    createdAt: reviewSnapshot.get('createdAt') ? moment(createdAt.toDate()) : moment(),
    updatedAt: reviewSnapshot.get('updatedAt') ? moment(updatedAt.toDate()) : moment(),
    triggerAt: reviewSnapshot.get('triggerAt') ? moment(triggerAt.toDate()) : undefined,
    reviewedAt: reviewSnapshot.get('reviewedAt') ? moment(reviewedAt.toDate()) : undefined,
    origin: origin ? (origin as ReviewOrigin) : ReviewOrigin.reputationApp,
    employeeResponse: employeeResponse ? employeeResponse : undefined,
  };
  return reviewData;
};

export const listenToReviews = (
  businessUid: string,
  listingUid: string,
  date?: Moment,
  limit: number = 10,
  lastDocument?: firestore.DocumentSnapshot,
): Observable<PaginationList<ReviewType>> => {
  let reviewsRef = FirestoreReference.Reviews()
    .where('businessUid', '==', businessUid)
    .where('listingUid', '==', listingUid);

  // TODO: Vamsee Chamakura 19/04/19 - Remove this conditional by making listingUid optional
  if (listingUid === 'allListings') {
    reviewsRef = FirestoreReference.Reviews().where('businessUid', '==', businessUid);
  }

  if (date) {
    reviewsRef = reviewsRef.where('createdAt', '>=', date.toDate()).where(
      'createdAt',
      '<=',
      date
        .add(7, 'days')
        .startOf('day')
        .toDate(),
    );
  }

  reviewsRef = reviewsRef.orderBy('createdAt', 'desc').limit(limit);

  if (lastDocument) {
    reviewsRef = reviewsRef.startAfter(lastDocument);
  }

  return RxFirestore.listenToQuery(reviewsRef).pipe(
    switchMap(querySnapshot => {
      const reviews = querySnapshot.docs.map(createReview);
      const lastReview = last(reviews);
      if (!lastReview) {
        return of({
          data: reviews,
          lastDocument: last(querySnapshot.docs) || lastDocument,
        });
      }
      let lastDayReviewsRef = FirestoreReference.Reviews()
        .where('createdAt', '>=', lastReview.createdAt.startOf('day').toDate())
        .where('createdAt', '<=', lastReview.createdAt.endOf('day').toDate())
        .where('businessUid', '==', businessUid)
        .orderBy('createdAt', 'desc');

      if (listingUid !== 'allListings') {
        lastDayReviewsRef = lastDayReviewsRef.where('listingUid', '==', listingUid);
      }

      return RxFirestore.listenToQuery(lastDayReviewsRef).pipe(
        map(lastDayReviews => {
          if (lastDayReviews.docs.length === 0) {
            return {
              data: reviews,
              lastDocument: last(querySnapshot.docs) || lastDocument,
            };
          }
          return {
            data: unionBy(reviews, lastDayReviews.docs.map(createReview), 'uid').filter(
              review => review.rating && review.rating <= 5 && review.rating > 0,
            ),
            lastDocument: last(lastDayReviews.docs) || lastDocument,
          };
        }),
      );
    }),
  );
};

export const listenToCustomerReviews = (
  type: Routes,
  businessUid: string,
  listingUid: string,
  startDate: Moment,
  endDate: Moment,
): Observable<ReviewType[]> => {
  let reviewsRef = FirestoreReference.Reviews()
    .where('businessUid', '==', businessUid)
    .where(
      'reviewedAt',
      '>=',
      startDate
        .tz('Atlantic/Reykjavik')
        .startOf('day')
        .toDate(),
    )
    .where(
      'reviewedAt',
      '<=',
      endDate
        .tz('Atlantic/Reykjavik')
        .endOf('day')
        .toDate(),
    )
    .where('reviewStatus', '==', ReviewStatus.reviewed)
    .orderBy('reviewedAt', 'desc');

  // TODO: Vamsee Chamakura 19/04/19 - Remove this conditional by making listingUid optional
  if (listingUid !== 'allListings') {
    reviewsRef = reviewsRef.where('listingUid', '==', listingUid);
  }

  const filteringFn = (review: ReviewType) => {
    switch (type) {
      case Routes.HappyCustomers:
        return (review.rating || 0) === 5;
      case Routes.NeutralCustomers:
        return (review.rating || 0) === 4;
      case Routes.UnhappyCustomers:
        return (review.rating || 0) <= 3;
    }
  };

  return RxFirestore.listenToQuery(reviewsRef).pipe(
    map(querySnapshot => querySnapshot.docs.map(item => createReview(item)).filter(filteringFn)),
  );
};

export const getCustomerReviews = (
  businessUid: string,
  reviewerMobileNumber: string,
): Observable<ReviewType[]> => {
  const reviewsRef = FirestoreReference.Reviews()
    .where('businessUid', '==', businessUid)
    .where('reviewerMobileNumber', '==', reviewerMobileNumber)
    .orderBy('createdAt', 'desc');

  return RxFirestore.getQuery(reviewsRef).pipe(
    map(querySnapshot => querySnapshot.docs.map(item => createReview(item))),
  );
};

export const addReview = (review: ReviewType): Observable<boolean> => {
  const { uid, createdAt, updatedAt, ...payload } = review;
  return from(
    FirestoreReference.Reviews().add({
      ...payload,
      createdAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp(),
    }),
  ).pipe(mapTo(true));
};

export const updateReview = (reviewUid: string, updateObject: any): Observable<boolean> => {
  return from(
    FirestoreReference.Reviews()
      .doc(reviewUid)
      .update({
        ...updateObject,
        updatedAt: firestore.FieldValue.serverTimestamp(),
      }),
  ).pipe(mapTo(true));
};

// Review details

export const listenToReviewNotes = (reviewUid: string): Observable<ReviewNote[]> => {
  return RxFirestore.listenToQuery(
    FirestoreReference.ReviewNotes(reviewUid).orderBy('createdAt', 'desc'),
  ).pipe(
    map(querySnapshot => {
      return querySnapshot.docs.map(createReviewNote);
    }),
  );
};

export const getUserReviewNotes = async (reviewUid: string) => {
  return FirestoreReference.ReviewNotes(reviewUid)
    .where('type', '==', ReviewNoteType.User)
    .orderBy('createdAt', 'desc')
    .get()
    .then(querySnapshot => {
      return querySnapshot.docs.map(createReviewNote);
    });
};

export const addReviewNote = (
  reviewUid: string,
  description: string,
  employeeUid: string,
  employeeName: string,
) => {
  const batch = firestore().batch();
  batch.update(FirestoreReference.Reviews().doc(reviewUid), {
    lastNote: {
      description,
      employeeUid,
      employeeName,
      createdAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp(),
      type: ReviewNoteType.User,
    },

    // TODO: Change it to firestore.FieldValue.increment(1) after updating the library
    notesCount: firestore.FieldValue.increment(1),
  });
  batch.set(FirestoreReference.ReviewNotes(reviewUid).doc(), {
    description,
    employeeUid,
    employeeName,
    createdAt: firestore.FieldValue.serverTimestamp(),
    updatedAt: firestore.FieldValue.serverTimestamp(),
    type: ReviewNoteType.User,
  });
  return from(batch.commit());
};

export const markReviewAs = (
  reviewUid: string,
  stage: ResolutionStageType,
  employeeUid: string,
  employeeName: string,
) => {
  const batch = firestore().batch();
  batch.set(FirestoreReference.ReviewNotes(reviewUid).doc(), {
    employeeUid,
    employeeName,
    description: `${employeeName} marked this as ${stage.name}.`,
    createdAt: firestore.FieldValue.serverTimestamp(),
    updatedAt: firestore.FieldValue.serverTimestamp(),
    type: ReviewNoteType.System,
  });
  batch.update(FirestoreReference.Reviews().doc(reviewUid), {
    resolved: stage.isResolvedStage,
    resolutionStatus: stage.name,
    resolvedAt: stage.isResolvedStage ? firestore.FieldValue.serverTimestamp() : null,
    resolvedBy: {
      id: employeeUid,
      name: employeeName,
    },
    updatedAt: firestore.FieldValue.serverTimestamp(),
  });
  return from(batch.commit());
};

export const deleteReview = (reviewUid: string) => {
  return from(
    FirestoreReference.Reviews()
      .doc(reviewUid)
      .delete(),
  );
};
