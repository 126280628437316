import { RootEpic } from '.';
import { combineLatest, of } from 'rxjs';
import { filter, map, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import {
  setUnhappyCustomerReviews,
  setHappyCustomerReviews,
  setNeutralCustomerReviews,
  setAverageTurnaroundTime,
} from '..';
import { union, groupBy } from 'lodash';
import { ReviewRepo, ReviewType, StatsRepo, AverageTurnaroundTime } from '../../repos';
import { SectionListData } from '../../utils';
import { setError, HomeActionTypes } from '../actions';
import { Moment } from 'moment-timezone';
import { Routes } from '../../App';

export const getUnhappyCustomerReviewsEpic: RootEpic = (action$, store) => {
  return combineLatest(
    action$.pipe(filter(isOfType(HomeActionTypes.GetUnhappyCustomerReviews))),
    store.pipe(
      map(state => {
        const { currentListingUid, businessProfile } = state.BusinessProfile;
        if (currentListingUid && businessProfile && businessProfile.businessUid) {
          return [currentListingUid, businessProfile.businessUid] as [string, string];
        }
        return undefined;
      }),
      filter(pair => !!pair),
      map(pair => pair!),
      distinctUntilChanged((prev, next) => {
        return union(prev, next).length === 2;
      }),
    ),
  ).pipe(
    map(([action, pair]) => {
      const [currentListingUid, businessUid] = pair!;
      return [action.payload, currentListingUid, businessUid] as [
        { startDate: Moment; endDate: Moment },
        string,
        string
      ];
    }),
    switchMap(([{ startDate, endDate }, currentListingUid, businessUid]) => {
      return ReviewRepo.listenToCustomerReviews(
        Routes.UnhappyCustomers,
        businessUid,
        currentListingUid,
        startDate,
        endDate,
      ).pipe(
        map(reviews => {
          const data: {
            resolved: SectionListData<ReviewType>[];
            unresolved: SectionListData<ReviewType>[];
          } = {
            resolved: Object.entries(
              groupBy(reviews.filter(review => review.resolved), review =>
                (review.reviewedAt || review.updatedAt).format("Do MMMM'YY"),
              ),
            ).map(([title, reviews]) => ({ title, data: reviews })),
            unresolved: Object.entries(
              groupBy(reviews.filter(review => !review.resolved), review =>
                (review.reviewedAt || review.updatedAt).format("Do MMMM'YY"),
              ),
            ).map(([title, reviews]) => ({ title, data: reviews })),
          };
          return setUnhappyCustomerReviews(data);
        }),
        catchError(error => of(setError(error.message))),
      );
    }),
  );
};

export const getHappyCustomerReviewsEpic: RootEpic = (action$, store) => {
  return combineLatest(
    action$.pipe(filter(isOfType(HomeActionTypes.GetHappyCustomerReviews))),
    store.pipe(
      map(state => {
        const { currentListingUid, businessProfile } = state.BusinessProfile;
        if (currentListingUid && businessProfile && businessProfile.businessUid) {
          return [currentListingUid, businessProfile.businessUid] as [string, string];
        }
        return undefined;
      }),
      filter(pair => !!pair),
      map(pair => pair!),
      distinctUntilChanged((prev, next) => {
        return union(prev, next).length === 2;
      }),
    ),
  ).pipe(
    map(([action, pair]) => {
      const [currentListingUid, businessUid] = pair!;
      return [action.payload, currentListingUid, businessUid] as [
        { startDate: Moment; endDate: Moment },
        string,
        string
      ];
    }),
    switchMap(([{ startDate, endDate }, currentListingUid, businessUid]) => {
      return ReviewRepo.listenToCustomerReviews(
        Routes.HappyCustomers,
        businessUid,
        currentListingUid,
        startDate,
        endDate,
      ).pipe(
        map(reviews => {
          const data = Object.entries(
            groupBy(reviews, review =>
              (review.reviewedAt || review.updatedAt).format("Do MMMM'YY"),
            ),
          ).map(([title, reviews]) => ({ title, data: reviews }));
          return setHappyCustomerReviews(data);
        }),
        catchError(error => of(setError(error.message))),
      );
    }),
  );
};

export const getNeutralCustomerReviewsEpic: RootEpic = (action$, store) => {
  return combineLatest(
    action$.pipe(filter(isOfType(HomeActionTypes.GetNeutralCustomerReviews))),
    store.pipe(
      map(state => {
        const { currentListingUid, businessProfile } = state.BusinessProfile;
        if (currentListingUid && businessProfile && businessProfile.businessUid) {
          return [currentListingUid, businessProfile.businessUid] as [string, string];
        }
        return undefined;
      }),
      filter(pair => !!pair),
      map(pair => pair!),
      distinctUntilChanged((prev, next) => {
        return union(prev, next).length === 2;
      }),
    ),
  ).pipe(
    map(([action, pair]) => {
      const [currentListingUid, businessUid] = pair!;
      return [action.payload, currentListingUid, businessUid] as [
        { startDate: Moment; endDate: Moment },
        string,
        string
      ];
    }),
    switchMap(([{ startDate, endDate }, currentListingUid, businessUid]) => {
      return ReviewRepo.listenToCustomerReviews(
        Routes.NeutralCustomers,
        businessUid,
        currentListingUid,
        startDate,
        endDate,
      ).pipe(
        map(reviews => {
          const data = Object.entries(
            groupBy(reviews, review =>
              (review.reviewedAt || review.updatedAt).format("Do MMMM'YY"),
            ),
          ).map(([title, reviews]) => ({ title, data: reviews }));
          return setNeutralCustomerReviews(data);
        }),
        catchError(error => of(setError(error.message))),
      );
    }),
  );
};

export const getAverageTurnaroundTimeEpic: RootEpic = (action$, store) => {
  return combineLatest(
    action$.pipe(filter(isOfType(HomeActionTypes.GetAverageTurnaroundTime))),
    store.pipe(
      map(state => {
        const { currentListingUid, businessProfile } = state.BusinessProfile;
        if (currentListingUid && businessProfile && businessProfile.businessUid) {
          return [currentListingUid, businessProfile.businessUid] as [string, string];
        }
        return undefined;
      }),
      filter(pair => !!pair),
      map(pair => pair!),
      distinctUntilChanged((prev, next) => {
        return union(prev, next).length === 2;
      }),
    ),
  ).pipe(
    map(([action, pair]) => {
      const [currentListingUid, businessUid] = pair!;
      return [action.payload, currentListingUid, businessUid] as [
        { startDate: Moment; endDate: Moment },
        string,
        string
      ];
    }),
    switchMap(([{ startDate, endDate }, currentListingUid, businessUid]) => {
      return StatsRepo.listenToAverageTurnaroundTime(
        businessUid,
        currentListingUid,
        startDate,
        endDate,
      ).pipe(
        map((averageTurnaroundTime: AverageTurnaroundTime) => {
          return setAverageTurnaroundTime(averageTurnaroundTime);
        }),
        catchError(error => of(setError(error.message))),
      );
    }),
  );
};
