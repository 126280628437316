import React, { useEffect, useState } from 'react';
import {
  RootState,
  getNeutralCustomerReviews,
  addNoteToReview,
  markReviewAs,
  getAverageTurnaroundTime,
} from '../../redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import { Modal, Input, message, Button, DatePicker, Empty } from 'antd';
import { SectionListData } from '../../utils';
import { ReviewType, BusinessProfileType, ResolutionStageType, ReviewRepo } from '../../repos';
import { ContainerLoading, ReviewCard, ReviewDetails } from '../../components';
import moment, { Moment } from 'moment-timezone';
import { saveAs } from 'file-saver';
import { flatten, sortBy } from 'lodash';
import { Colors } from '../../theme';

interface NeutralCustomersContainerProps {
  currentListingUid?: string;
  loading?: boolean;
  neutralCustomerReviews?: SectionListData<ReviewType>[];
  businessProfile?: BusinessProfileType;
  getNeutralCustomerReviews: typeof getNeutralCustomerReviews;
  addNoteToReview: typeof addNoteToReview;
  markReviewAs: typeof markReviewAs;
}

const NeutralCustomersContainer: React.SFC<
  RouteComponentProps<NeutralCustomersContainerProps>
> = props => {
  const {
    getNeutralCustomerReviews,
    addNoteToReview,
    markReviewAs,
    currentListingUid,
    loading,
    businessProfile,
    neutralCustomerReviews,
  } = props;

  const [addNotesPopupReviewData, setAddNotesPopupReviewData] = useState<{
    reviewUid?: string;
    stage?: ResolutionStageType;
  }>();
  const [selectedReview, setSelectedReview] = useState<ReviewType>();
  const [note, setNote] = useState<string>();
  const [processingDownload = false, setProcessingDownload] = useState<boolean>();

  // prettier-ignore
  const [
    selectedDates = [moment().startOf('day'), moment().endOf('day')],
    setSelectedDates,
  ] = useState<[Moment, Moment]| undefined>();

  useEffect(() => {
    if (!getNeutralCustomerReviews) {
      return;
    }
    const [startDate, endDate] = selectedDates;
    getNeutralCustomerReviews(startDate, endDate);
  }, [currentListingUid, selectedDates[0].format('DD MM YY'), selectedDates[1].format('DD MM YY')]);

  const onSubmit = (reviewUid: string, stage: ResolutionStageType) => {
    if (!note || note.length === 0) {
      message.error('Please enter a valid text');
      return;
    }
    markReview(reviewUid, stage);
    addNoteToReview && addNoteToReview(reviewUid, note);
    setNote('');
    setAddNotesPopupReviewData({ reviewUid: undefined, stage: undefined });
  };

  const markReview = (reviewUid: string, stage: ResolutionStageType) => {
    markReviewAs && stage && markReviewAs(reviewUid, stage);
  };

  /**
   * Using file-saver to create and download a file requires some tweaking around encoding of file.
   * To understand it more please go to:
   * https://github.com/eligrey/FileSaver.js/issues/28
   */
  const downloadData = async () => {
    if (!neutralCustomerReviews) {
      return;
    }

    message.info(
      <Button type="ghost" style={{ border: 'none' }} loading>
        Processing
      </Button>,
      0,
    );
    setProcessingDownload(true);

    const BOM = '\uFEFF';
    const reviewsFlattened: ReviewType[] = flatten(
      neutralCustomerReviews.map(sections => sections.data),
    );

    const reviewsAndRemarks: (ReviewType & { remarks?: string })[] = [];

    for (const review of reviewsFlattened) {
      const remarks = await ReviewRepo.getUserReviewNotes(review.uid).then(remarks => {
        if (remarks.length === 0) {
          return undefined;
        }
        return remarks
          .map(
            remark =>
              `[${remark.createdAt.format('DD-MM-YY hh:mm a')}] ${remark.employeeName}: ${
                remark.description
              }`,
          )
          .join('\n');
      });
      reviewsAndRemarks.push({ ...review, remarks });
    }
    const data = sortBy(reviewsAndRemarks, [review => review.createdAt.unix()]).map(review => {
      return [
        review.reviewerName || '', // Reviewer Name
        review.reviewerMobileNumber || '', // Reviewer Mobile Number
        review.rating || '', // Review rating
        (review.comment && `"${review.comment}"`) || '', // Review comments
        (review.remarks && `"${review.remarks}"`) || '', // Review Remarks
        review.createdAt.format(), // Review requested on
        review.updatedAt.format(), // Reviewed on
        review.resolved ? 'Yes' : 'No', // Is Resolved?
        review.resolved ? review.updatedAt.format() : '', // Resolved On
      ].join(',');
    });
    const headers = [
      'Name',
      'Mobile Number',
      'Rating',
      'Comment',
      'Remarks',
      'Request Date',
      'Review Date',
      'Is it resolved?',
      'Resolved On',
    ];
    data.unshift(headers.join(','));
    const csv = data.join('\r\n');

    const blob = new Blob([BOM + csv], { type: 'text/csv;charset=utf-8' });
    const fileName = `${selectedDates.map(date => date.format('DD-MM-YYYY')).join('-')}.csv`;
    saveAs(blob, fileName);

    message.destroy();
    setProcessingDownload(false);
  };

  // Render methods

  const renderReviews = () => {
    if (loading) {
      return <ContainerLoading />;
    }
    if (!neutralCustomerReviews || !businessProfile) {
      return;
    }
    if (neutralCustomerReviews.length === 0) {
      return (
        <Empty
          style={{
            marginTop: '20vh',
            marginBottom: '20vh',
          }}
          description="No reviews available"
        />
      );
    }
    return neutralCustomerReviews.map(section => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          key={section.title}>
          <div style={{ fontWeight: 500, paddingTop: 24, paddingBottom: 24, alignSelf: 'center' }}>
            {section.title}
          </div>
          {section.data.map(review => (
            <ReviewCard
              stages={businessProfile.resolutionStages}
              showReviewDetails={() => setSelectedReview(review)}
              showAddNotes={stage => setAddNotesPopupReviewData({ stage, reviewUid: review.uid })}
              key={review.uid}
              review={review}
            />
          ))}
        </div>
      );
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: Colors.Grey100,
      }}>
      {addNotesPopupReviewData &&
        addNotesPopupReviewData.reviewUid &&
        addNotesPopupReviewData.stage &&
        businessProfile && (
          <Modal
            title="Add remarks"
            onCancel={() => {
              setAddNotesPopupReviewData({ reviewUid: undefined, stage: undefined });
            }}
            footer={[
              <Button
                key="back"
                disabled={!businessProfile.skippableRemarks}
                onClick={() => {
                  markReview(addNotesPopupReviewData.reviewUid!, addNotesPopupReviewData.stage!);
                  setAddNotesPopupReviewData({ reviewUid: undefined, stage: undefined });
                }}>
                Skip
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() =>
                  onSubmit(addNotesPopupReviewData.reviewUid!, addNotesPopupReviewData.stage!)
                }>
                Add Remarks
              </Button>,
            ]}
            visible>
            <Input.TextArea
              onChange={event => {
                setNote(event.target.value);
              }}
              rows={4}
              placeholder="Remarks"
            />
          </Modal>
        )}
      {selectedReview && (
        <Modal
          visible
          okText="Add Remarks"
          onCancel={() => setSelectedReview(undefined)}
          width={'70%'}
          onOk={() =>
            setAddNotesPopupReviewData({ reviewUid: selectedReview.uid, stage: undefined })
          }>
          <ReviewDetails onDelete={() => setSelectedReview(undefined)} review={selectedReview} />
        </Modal>
      )}
      <div
        style={{
          maxWidth: 1024,
          marginTop: 16,
          marginBottom: 16,
          display: 'flex',
          flex: 1,
          width: '100%',
          padding: 0,
        }}>
        <span style={{ alignSelf: 'center', marginRight: 8, color: 'black', fontWeight: 'bold' }}>
          Review Date:
        </span>
        <DatePicker.RangePicker
          allowClear={false}
          separator="to"
          onChange={dates => setSelectedDates(dates as [Moment, Moment])}
          defaultValue={selectedDates as [Moment, Moment]}
        />
        <Button
          onClick={downloadData}
          icon="cloud-download"
          disabled={processingDownload}
          loading={processingDownload}
          type="primary"
          style={{ marginLeft: 'auto' }}>
          Download
        </Button>
      </div>
      <div
        style={{
          margin: 20,
          boxShadow: '1px 1px 8px #d0d0d0',
          maxWidth: 1024,
          width: '100%',
          paddingLeft: 16,
          backgroundColor: Colors.White100,
          paddingRight: 16,
        }}>
        <div style={{ marginLeft: 24, marginRight: 24 }}>{renderReviews()}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { currentListingUid, businessProfile } = state.BusinessProfile;
  const { neutralCustomerReviews, loading } = state.Home;
  return {
    currentListingUid,
    neutralCustomerReviews,
    loading,
    businessProfile,
  };
};

export default connect(
  mapStateToProps,
  {
    getNeutralCustomerReviews,
    addNoteToReview,
    markReviewAs,
    getAverageTurnaroundTime,
  },
)(NeutralCustomersContainer);
