import React from 'react';
import { Colors } from '../../theme';
import { averageTurnaroundTimeIcon } from '../../assets';
import { RootState } from '../../redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { ContainerLoading } from '../../components';
import { AverageTurnaroundTime } from '../../repos';
import { capitalize } from 'lodash';

interface AverageTurnaroundTimeCardProps {
  averageTurnaroundTime?: AverageTurnaroundTime;
}

const AverageResolutionTimeCard: React.SFC<AverageTurnaroundTimeCardProps> = props => {
  const { averageTurnaroundTime } = props;
  if (!averageTurnaroundTime) {
    return <ContainerLoading />;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        flex: 1,
        margin: 10,
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: 20,
          marginTop: 4,
        }}>
        <img src={averageTurnaroundTimeIcon} alt="Turnaround Time" height="64" />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            marginLeft: 20,
          }}>
          <div
            style={{
              color: Colors.Steel500,
              fontSize: 24,
              fontWeight: 'bolder',
            }}>
            {averageTurnaroundTime.averageResolutionTime > 0
              ? capitalize(
                  moment
                    .duration(averageTurnaroundTime.averageResolutionTime, 'milliseconds')
                    .humanize(),
                )
              : 'N/A'}
          </div>
          <div
            style={{
              color: Colors.Steel200,
              fontSize: 16,
            }}>
            Avg. Resolution Time
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { averageTurnaroundTime } = state.Home;
  return { averageTurnaroundTime };
};

export default connect(
  mapStateToProps,
  {},
)(AverageResolutionTimeCard);
