import React from 'react';
import { Dots, Footer, SectionTitle } from '../../../components';
import { Colors } from '../../../theme';
import { LocationLeaderboardProps } from '../../../repos';

export const LocationLeaderboard: React.SFC<LocationLeaderboardProps> = props => {
  const { bestLocation, data } = props;

  return (
    <div
      style={{
        alignItems: 'stretch',
        flexDirection: 'row',
        display: 'flex',
        fontFamily: 'Lato',
      }}>
      <Dots style={{ position: 'absolute', margin: 24 }} dotscolor={Colors.TextDarker} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '64px 16px 48px 48px',
        }}>
        <SectionTitle title="Location leaderboard" section={4} />
        <div
          style={{
            fontSize: 18,
            marginTop: 24,
            marginBottom: 24,
            fontWeight: 700,
            color: Colors.TextDarker,
            textTransform: 'uppercase',
          }}>
          Location leaderboard
        </div>
        <div style={{ margin: '24px 0' }}>Best location: {bestLocation}</div>
        <div
          style={{
            display: 'grid',
            marginTop: 24,
            marginBottom: 24,
            gridTemplateColumns: '1.2fr repeat(6, 1fr)',
            gridTemplateRows: 'auto',
            color: Colors.Text,
            gridColumnGap: 8,
            gridRowGap: 8,
          }}>
          <div style={{ fontSize: 12, fontWeight: 700, opacity: 0.5, textAlign: 'center' }}>
            Location
          </div>
          <div style={{ fontSize: 12, fontWeight: 700, opacity: 0.5, textAlign: 'center' }}>
            Week NPS
          </div>
          <div style={{ fontSize: 12, fontWeight: 700, opacity: 0.5, textAlign: 'center' }}>
            Week Total Ratings
          </div>
          <div style={{ fontSize: 12, fontWeight: 700, opacity: 0.5, textAlign: 'center' }}>
            Week Avg Rating
          </div>
          <div style={{ fontSize: 12, fontWeight: 700, opacity: 0.5, textAlign: 'center' }}>
            Overall NPS
          </div>
          <div style={{ fontSize: 12, fontWeight: 700, opacity: 0.5, textAlign: 'center' }}>
            Overall Total Ratings
          </div>
          <div style={{ fontSize: 12, fontWeight: 700, opacity: 0.5, textAlign: 'center' }}>
            Overall Avg Rating
          </div>
          <div
            style={{
              gridColumn: '1 / 8',
              backgroundColor: Colors.TextDarker,
              opacity: 0.1,
              height: 1,
              margin: '10px 0',
            }}
          />
          {data
            .filter(item => item.weekTotalRating !== 0)
            .map((item, parentIndex) =>
              Object.entries(item).map(([key, value], index) => {
                return (
                  <div
                    key={`${key}${parentIndex}${index}`}
                    style={{
                      fontSize: 13,
                      gridColumn: index + 1,
                      justifySelf: index !== 0 ? 'center' : 'start',
                    }}>
                    {value}
                  </div>
                );
              }),
            )
            .reduce((acc, item) => acc.concat(item), [])}
        </div>
      </div>
      <Footer
        style={{
          position: 'absolute',
          width: 'calc(100% - 64px)',
          alignSelf: 'flex-end',
          marginLeft: 48,
        }}
        page={3}
      />
    </div>
  );
};
