import { RootEpic } from '.';
import { filter, map, switchMap, catchError, distinctUntilChanged } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { ReportActionTypes, setError, setReport } from '../actions';
import { from, of, combineLatest } from 'rxjs';
import { ReportRepo } from '../../repos';

export const getReportEpic: RootEpic = (action$, store) => {
  const businessProfileObservable = store.pipe(
    map(
      state =>
        state.BusinessProfile.businessProfile && state.BusinessProfile.businessProfile.businessUid,
    ),
    filter(businessUid => !!businessUid),
    distinctUntilChanged(),
    map(businessUid => businessUid!),
  );
  return combineLatest(
    action$.pipe(filter(isOfType(ReportActionTypes.GetReport))),
    businessProfileObservable,
  ).pipe(
    switchMap(([action, businessUid]) => {
      const { startDate, endDate } = action.payload;
      return from(ReportRepo.getReportData(businessUid, startDate, endDate)).pipe(
        map(report => {
          return setReport(report);
        }),
        catchError(error => {
          return of(setError(error.message));
        }),
      );
    }),
  );
};
