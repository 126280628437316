import React from 'react';
import { Dots, SectionTitle, Footer } from '../../../components';
import { Colors } from '../../../theme';
import { EmployeeLeaderboardProps } from '../../../repos';
import { sumBy } from 'lodash';

export const EmployeeLeaderboard: React.SFC<EmployeeLeaderboardProps> = props => {
  const { bestEmployee, data } = props;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '24px 24px',
        fontFamily: 'Lato',
      }}>
      <Dots dotscolor={Colors.TextDarker} />
      <div style={{ display: 'flex', flexDirection: 'column', padding: '24px 24px' }}>
        <SectionTitle style={{ marginTop: 24 }} section={5} title="Employee Leaderboard" />
        <div style={{ marginTop: 24, color: Colors.Text, fontSize: 16 }}>
          Best Employee: {bestEmployee}
        </div>
        {data
          .filter(item => sumBy(item.stats, x => x.weekTotalRating) !== 0)
          .map(item => {
            return (
              <div key={item.location} style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{
                    fontSize: 18,
                    marginTop: 24,
                    marginBottom: 24,
                    fontWeight: 700,
                    color: Colors.TextDarker,
                    textTransform: 'uppercase',
                  }}>
                  {item.location} - Employee Dashboard
                </div>
                <div
                  style={{
                    display: 'grid',
                    marginBottom: 24,
                    marginRight: 24,
                    gridTemplateColumns: '3fr repeat(4, 1fr)',
                    gridTemplateRows: 'auto',
                    color: Colors.Text,
                    gridColumnGap: 8,
                    gridRowGap: 8,
                  }}>
                  <div style={{ fontSize: 12, fontWeight: 700, opacity: 0.5, textAlign: 'center' }}>
                    Location
                  </div>
                  <div style={{ fontSize: 12, fontWeight: 700, opacity: 0.5, textAlign: 'center' }}>
                    Week NPS
                  </div>
                  <div style={{ fontSize: 12, fontWeight: 700, opacity: 0.5, textAlign: 'center' }}>
                    Week Total Ratings
                  </div>
                  <div style={{ fontSize: 12, fontWeight: 700, opacity: 0.5, textAlign: 'center' }}>
                    Week Avg Ratings
                  </div>
                  <div style={{ fontSize: 12, fontWeight: 700, opacity: 0.5, textAlign: 'center' }}>
                    Overall NPS
                  </div>
                  <div style={{ fontSize: 12, fontWeight: 700, opacity: 0.5, textAlign: 'center' }}>
                    Overall Total Ratings
                  </div>
                  <div style={{ fontSize: 12, fontWeight: 700, opacity: 0.5, textAlign: 'center' }}>
                    Overall Avg Ratings
                  </div>
                  <div
                    style={{
                      gridColumn: '1 / 8',
                      backgroundColor: Colors.TextDarker,
                      opacity: 0.1,
                      height: 1,
                      margin: '10px 0',
                    }}
                  />
                  {item.stats
                    .filter(item => item.weekTotalRating !== 0)
                    .map((item, parentIndex) =>
                      Object.entries(item).map(([key, value], index) => {
                        return (
                          <div
                            key={`${key}${parentIndex}${index}`}
                            style={{
                              fontSize: 13,
                              gridColumn: index + 1,
                              justifySelf: index !== 0 ? 'center' : 'start',
                            }}>
                            {value}
                          </div>
                        );
                      }),
                    )
                    .reduce((acc, item) => acc.concat(item), [])}
                </div>
              </div>
            );
          })}
      </div>
      <Footer
        style={{
          width: 'calc(100% - 48px)',
          marginTop: 'auto',
        }}
        page={4}
      />
    </div>
  );
};
