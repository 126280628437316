import { action, ActionType } from 'typesafe-actions';

export enum CommonActionTypes {
  SetError = 'Common/SetError',
  SetMessage = 'Common/SetMessage',
}

export const setError = (
  error?: string,
  ) => action(
    CommonActionTypes.SetError,
    { error },
  );

export const setMessage = (
  message?: string,
) => action(
  CommonActionTypes.SetMessage,
  { message },
);

const commonActions = {
  setError,
  setMessage,
};

export type CommonAction = ActionType<typeof commonActions>;
