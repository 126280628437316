import { isOfType } from 'typesafe-actions';
import { of, combineLatest, from } from 'rxjs';
import { filter, switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';

import {
  setCurrentUser,
  setBusinessProfile,
  setEmployeeProfile,
  setCurrentListingUid,
  setAssignedListings,
  setAssociatedEmployees,
} from '..';
import { AuthRepo, BusinessProfileRepo } from '../../repos';
import { EmployeeProfileType } from '../../repos/BusinessProfile';
import { RootEpic } from '.';
import { setError, setMessage, BusinessProfileActionTypes, AuthActionTypes } from '../actions';
import { first } from 'lodash';

export const signInEpic: RootEpic = action$ => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.SignIn)),
    switchMap(action => {
      const { email, password } = action.payload;
      if (!email) {
        return of(setError('Please enter your email'));
      }
      if (!password) {
        return of(setError('Please enter your password'));
      }
      return AuthRepo.signIn(email.toLowerCase().trim(), password).pipe(
        map(userCredential => {
          return setCurrentUser(userCredential.user);
        }),
        catchError(error => of(setError(error.message))),
      );
    }),
  );
};

export const sendRecoveryEmailEpic: RootEpic = action$ => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.SendRecoveryEmail)),
    switchMap(action => {
      return AuthRepo.sendRecoveryEmail(action.payload.email.toLowerCase().trim()).pipe(
        map(() => setMessage('Recovery email sent!')),
        catchError(error => of(setError(error.message))),
      );
    }),
  );
};

export const listenToAuthStateEpic: RootEpic = action$ => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.ListenToAuthState)),
    switchMap(() => {
      return AuthRepo.listenToAuthState().pipe(
        map(user => {
          return setCurrentUser(user);
        }),
        catchError(error => of(setError(error.message))),
      );
    }),
  );
};

export const getEmployeeProfileEpic: RootEpic = action$ => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.SetCurrentUser)),
    filter(action => !!action.payload.currentUser),
    map(action => action.payload.currentUser!),
    switchMap(user => {
      return BusinessProfileRepo.listenToEmployeeProfile(user!.uid).pipe(
        map(employeeProfile => setEmployeeProfile(employeeProfile)),
      );
    }),
  );
};

export const listenToBusinessProfileEpic: RootEpic = action$ => {
  return action$.pipe(
    filter(isOfType(BusinessProfileActionTypes.SetEmployeeProfile)),
    switchMap(action => {
      const { employeeProfile } = action.payload;
      const businessUid = first(Object.keys(employeeProfile.associatedListings));
      if (!businessUid) {
        return of(setError('There are no associated businesses'));
      }
      return BusinessProfileRepo.listenToBusinessProfile(businessUid).pipe(
        map(businessProfile => {
          return setBusinessProfile(businessProfile);
        }),
        catchError(error => of(setError(error.message))),
      );
    }),
  );
};

export const listenToAssociatedEmployees: RootEpic = action$ => {
  return action$.pipe(
    filter(isOfType(BusinessProfileActionTypes.SetBusinessProfile)),
    switchMap(action => {
      const { businessProfile } = action.payload;
      return combineLatest(
        businessProfile.employees.map(employeeUid =>
          BusinessProfileRepo.listenToEmployeeProfile(employeeUid),
        ),
      ).pipe(
        map(employeeProfiles => {
          return setAssociatedEmployees(
            employeeProfiles.reduce(
              (acc, item) => {
                acc[item.employeeUid] = item;
                return acc;
              },
              {} as { [key: string]: EmployeeProfileType },
            ),
          );
        }),
        catchError(error => of(setError(error.message))),
      );
    }),
  );
};

export const listenToAssociatedListings: RootEpic = action$ => {
  const businessProfileObservable = action$.pipe(
    filter(isOfType(BusinessProfileActionTypes.SetBusinessProfile)),
  );
  const employeeProfileObservable = action$.pipe(
    filter(isOfType(BusinessProfileActionTypes.SetEmployeeProfile)),
  );

  return combineLatest(businessProfileObservable, employeeProfileObservable).pipe(
    switchMap(([businessProfileAction, employeeProfileAction]) => {
      const { businessProfile } = businessProfileAction.payload;
      const employeeListings = employeeProfileAction.payload.employeeProfile.associatedListings[
        businessProfile.businessUid
      ].reduce(
        (acc, item) => {
          acc[item] = businessProfile.listings[item].listingName;
          return acc;
        },
        {} as { [key: string]: string },
      );
      const isAllCentresEnabled =
        Object.keys(employeeListings).length === Object.keys(businessProfile.listings).length;
      return from([
        setAssignedListings(employeeListings),
        setCurrentListingUid(
          isAllCentresEnabled ? 'allListings' : Object.keys(employeeListings)[0],
        ),
      ]);
    }),
  );
};

export const logoutEpic: RootEpic = (action$, store) => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.Logout)),
    withLatestFrom(store),
    switchMap(([, state]) => {
      const { currentUser } = state.Auth;
      if (!currentUser) {
        return of(setError('You are already signed out'));
      }
      return AuthRepo.logout().pipe(map(() => setMessage('You have been logged out')));
    }),
  );
};
