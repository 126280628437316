import { AuthAction, AuthActionTypes } from '../actions';
import { produce } from 'immer';
import { User } from 'firebase';

export interface AuthStateType {
  readonly currentUser?: User | null;
  readonly loading: boolean;
}

const initialState: AuthStateType = {
  loading: false,
};

export const AuthReducer = (currentState: AuthStateType = initialState, action: AuthAction) => {
  return produce(currentState, draft => {
    draft.loading = false;
    switch (action.type) {
      case AuthActionTypes.SignIn:
      case AuthActionTypes.SendRecoveryEmail:
        draft.loading = true;
        break;
      case AuthActionTypes.SetCurrentUser:
        draft.currentUser = action.payload.currentUser;
        break;
      default:
        break;
    }
    return draft;
  });
};
