import React from 'react';
import { Row, Col, message } from 'antd';
import { connect } from 'react-redux';
import { RootState, signIn } from '../../redux';
import { SignInFormComponent } from './SignInForm';
import { RouteComponentProps } from '@reach/router';
import { pulseLogo } from '../../assets';

interface SignInProps {
  signIn: typeof signIn;
  loading?: boolean;
  error?: string;
  message?: string;
}

class SignInContainer extends React.Component<RouteComponentProps<SignInProps>> {

  componentWillReceiveProps(nextProps: SignInProps) {
    if (nextProps.error) {
      message.error(nextProps.error);
    }
    if (nextProps.message) {
      message.success(nextProps.message);
    }
  }

  render() {
    if (!this.props.signIn) { return null; }
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
      }}>
        <Row style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
          <Col
          style={{ display: 'flex', flexDirection: 'column' }}
          xxl={4}
          xs={14}
          xl={5}
          lg={6}
          md={8}
          sm={10}>
            <div style={{ alignSelf: 'center', marginBottom: 24 }}>
              <img height={64} src={pulseLogo}/>
              <p style={{ textAlign: 'right', opacity: 0.6, marginTop: -10 }}>
                Reputation Management
              </p>
            </div>
            <SignInFormComponent loading={this.props.loading} onSubmit={this.props.signIn}/>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const { loading } = state.Auth;
  const { error, message } = state.Common;
  return {
    loading,
    error,
    message,
  };
};

export default connect(mapStateToProps, { signIn })(SignInContainer);
