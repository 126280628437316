import { ActionType, action } from 'typesafe-actions';
import { BusinessProfileType, EmployeeProfileType } from '../../repos';

export enum BusinessProfileActionTypes {
  SetBusinessProfile = 'BusinessProfile/SetBusinessProfile',
  SetEmployeeProfile = 'BusinessProfile/SetEmployeeProfile',
  SetCurrentListingUid = 'BusinessProfile/SetCurrentListingUid',
  SetAssignedListings = 'BusinessProfile/SetAssignedListings',
  SetAssociatedEmployees = 'BusinessProfile/SetAssociatedEmployees',
}

export const setEmployeeProfile = (
  employeeProfile: EmployeeProfileType,
) => action(
  BusinessProfileActionTypes.SetEmployeeProfile,
  { employeeProfile },
);

export const setBusinessProfile = (
  businessProfile: BusinessProfileType,
) => action(
  BusinessProfileActionTypes.SetBusinessProfile,
  { businessProfile },
);

export const setCurrentListingUid = (
  currentListingUid: string,
) => action(
  BusinessProfileActionTypes.SetCurrentListingUid,
  { currentListingUid },
);

export const setAssignedListings = (
  employeeListings: { [key: string]: string },
) => action(
  BusinessProfileActionTypes.SetAssignedListings,
  { employeeListings },
);

export const setAssociatedEmployees = (
  associatedEmployees?: { [key: string]: EmployeeProfileType },
) => action(
  BusinessProfileActionTypes.SetAssociatedEmployees,
  { associatedEmployees },
);

const businessProfileActionTypes = {
  setEmployeeProfile,
  setBusinessProfile,
  setCurrentListingUid,
  setAssignedListings,
  setAssociatedEmployees,
};

export type BusinessProfileAction = ActionType<typeof businessProfileActionTypes>;
