import React, { useState } from 'react';
import { Link, navigate } from '@reach/router';
import { Menu, Icon, Avatar } from 'antd';
import { Routes } from '../../App';
import { pulseLogo } from '../../assets';
import { connect } from 'react-redux';
import { logout, setCurrentListingUid, RootState } from '../../redux';
import { Colors } from '../../theme';
import { startCase } from 'lodash';
import { EmployeeProfileType } from '../../repos';

enum HomeTabs {
  UnhappyCustomers = 'Unhappy Customers',
  HappyCustomers = 'Happy Customers',
  NeutralCustomers = 'Neutral Customers',
  Reports = 'Reports',
}

enum DropDownOptions {
  Logout = 'Logout',
  SelectListing = 'Select Listing',
}

interface NavbarProps {
  logout?: typeof logout;
  setCurrentListingUid?: typeof setCurrentListingUid;
  employeeName?: string;
  employeeListings?: { [key: string]: string };
  listingName?: string;
  employeeProfile?: EmployeeProfileType;
  enableAllCentres: boolean;
}

const Navbar: React.SFC<NavbarProps> = props => {
  const {
    employeeListings,
    listingName,
    employeeName,
    employeeProfile,
    enableAllCentres,
    logout,
    setCurrentListingUid,
  } = props;

  const [
    selectedTab = window.location.pathname === '/'
      ? HomeTabs.UnhappyCustomers
      : startCase(window.location.pathname),
    setSelectedTab,
  ] = useState<HomeTabs>();

  const renderListings = () => {
    if (!employeeListings) {
      return null;
    }
    if (enableAllCentres) {
      employeeListings.allListings = 'All Centres';
    }
    return Object.entries(employeeListings)
      .sort(([, a], [, b]) => (a === 'All Centres' ? -1 : a > b ? 1 : -1))
      .map(([listingUid, name]) => {
        return (
          <Menu.Item
            onClick={() => setCurrentListingUid && setCurrentListingUid(listingUid)}
            key={listingUid}>
            {name}
          </Menu.Item>
        );
      });
  };

  const rightButton = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar style={{ backgroundColor: Colors.Pink300 }}>
        {(employeeName && employeeName.charAt(0)) || 'U'}
      </Avatar>
      <div style={{ flexDirection: 'column', marginLeft: 10, alignItems: 'center' }}>
        <div style={{ fontSize: 15, lineHeight: 'normal' }}>{listingName}</div>
        <div
          style={{
            fontSize: 9,
            lineHeight: 'normal',
            color: Colors.Steel200,
            marginTop: 2,
            marginLeft: 2,
          }}>
          {employeeName}
        </div>
      </div>
      <Icon type="caret-down" style={{ fontSize: 10, opacity: 0.5, marginLeft: 10 }} />
    </div>
  );

  return (
    <div
      style={{
        backgroundColor: '#FFF',
        zIndex: 100,
        position: 'fixed',
        width: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px',
      }}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          padding: '5px 20px 6px',
          borderBottom: '0.25px solid rgba(0,0,0,0.3)',
        }}>
        <Link to={Routes.Home}>
          <img src={pulseLogo} />
        </Link>
        <Menu
          className="simple-menu"
          selectedKeys={[]}
          style={{ marginLeft: 'auto', lineHeight: 'normal', marginTop: 8, marginBottom: 8 }}
          mode="horizontal">
          <Menu.SubMenu className="simple-menu" title={rightButton}>
            <Menu.SubMenu
              key={DropDownOptions.SelectListing}
              title={
                <span>
                  <Icon type="sync" />
                  <span>{DropDownOptions.SelectListing}</span>
                </span>
              }>
              {renderListings()}
            </Menu.SubMenu>
            <Menu.Item onClick={logout} key={DropDownOptions.Logout}>
              <Icon type="poweroff" />
              <span>{DropDownOptions.Logout}</span>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </div>
      <Menu
        selectedKeys={[selectedTab]}
        style={{
          marginTop: 10,
          display: 'flex',
          justifyContent: 'center',
        }}
        mode="horizontal">
        {employeeProfile && employeeProfile.featureOptIn.showReportsInReputationWeb && (
          <Menu.Item
            key={HomeTabs.Reports}
            onClick={() => {
              setSelectedTab(HomeTabs.Reports);
              navigate(Routes.Reports);
            }}>
            <Icon type="line-chart" />
            <span>{HomeTabs.Reports}</span>
          </Menu.Item>
        )}
        <Menu.Item
          key={HomeTabs.HappyCustomers}
          onClick={() => {
            setSelectedTab(HomeTabs.HappyCustomers);
            navigate(Routes.HappyCustomers);
          }}>
          <Icon type="smile" />
          <span>{HomeTabs.HappyCustomers}</span>
        </Menu.Item>
        <Menu.Item
          key={HomeTabs.NeutralCustomers}
          onClick={() => {
            setSelectedTab(HomeTabs.NeutralCustomers);
            navigate(Routes.NeutralCustomers);
          }}>
          <Icon type="robot" />
          <span>{HomeTabs.NeutralCustomers}</span>
        </Menu.Item>
        <Menu.Item
          key={HomeTabs.UnhappyCustomers}
          onClick={() => {
            setSelectedTab(HomeTabs.UnhappyCustomers);
            navigate(Routes.UnhappyCustomers);
          }}>
          <Icon type="frown" />
          <span>{HomeTabs.UnhappyCustomers}</span>
        </Menu.Item>
      </Menu>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    currentListingUid,
    employeeListings,
    employeeProfile,
    businessProfile,
  } = state.BusinessProfile;
  return {
    currentListingUid,
    employeeListings,
    enableAllCentres:
      Object.keys(employeeListings || {}).length ===
      Object.keys((businessProfile || { listings: {} }).listings).length,
    employeeProfile,
  };
};

export default connect(
  mapStateToProps,
  { logout, setCurrentListingUid },
)(Navbar);
