import { action, ActionType } from 'typesafe-actions';
import { User } from 'firebase';

export enum AuthActionTypes {
  SignIn = 'Auth/SignIn',
  Logout = 'Auth/Logout',
  SetCurrentUser = 'Auth/SetCurrentUser',
  ListenToAuthState = 'Auth/ListenToAuthState',
  SendRecoveryEmail = 'Auth/SendRecoveryEmail',
}

export const signIn = (email?: string, password?: string) =>
  action(AuthActionTypes.SignIn, { email, password });

export const sendRecoveryEmail = (email: string) =>
  action(AuthActionTypes.SendRecoveryEmail, { email });

export const logout = () => action(AuthActionTypes.Logout);

export const listenToAuthState = () => action(AuthActionTypes.ListenToAuthState);

export const setCurrentUser = (currentUser: User | null) =>
  action(AuthActionTypes.SetCurrentUser, { currentUser });

const authActions = {
  signIn,
  sendRecoveryEmail,
  logout,
  listenToAuthState,
  setCurrentUser,
};

export type AuthAction = ActionType<typeof authActions>;
