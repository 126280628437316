import { Moment } from 'moment';
import Environment from '../../environment.json';

export interface HomeProps {
  organizationName: string;
  dates: [number, number];
  overall: {
    measure: number;
    act: number;
    grow?: number;
  };
  nps: number;
  averageRating: number;
  totalRequests: number;
  totalRatings: number;
  unhappyCustomers: number;
}

export interface MeasureAndActProps {
  measure: {
    title: string;
    progress: number;
  }[];
  act: {
    title: string;
    progress: number;
  }[];
}

export interface NPSProps {
  breakdown: {
    promoters: number;
    neutrals: number;
    detractors: number;
  };
  weeklyTrend: {
    week: 1 | 2 | 3 | 4;
    npsScore: number;
  }[];
}

export interface AverageRatingProps {
  ratingData: {
    rating: 1 | 2 | 3 | 4 | 5;
    count: number;
  }[];
  weeklyTrend: {
    week: 1 | 2 | 3 | 4;
    value: number;
  }[];
}

export interface TextCommentProps {
  comments: {
    customerName: string;
    mobileNumber: string;
    branch: string;
    employeeName: string;
    comments: string;
  }[];
}

export interface LocationLeaderboardProps {
  bestLocation: string;
  data: {
    location: string;
    weekNPS: number;
    weekTotalRating: number;
    weekAverageRating: number;
    overallNPS: number;
    overallTotalRating: number;
    overallAverageRating: number;
  }[];
}

export interface EmployeeLeaderboardProps {
  bestEmployee: string;
  data: {
    location: string;
    stats: {
      employeeName: string;
      weekNPS: number;
      weekTotalRating: number;
      weekAverageRating: number;
      overallNPS: number;
      overallTotalRating: number;
      overallAverageRating: number;
    }[];
  }[];
}

export interface Report {
  home: HomeProps;
  measureAndAct: MeasureAndActProps;
  nps: NPSProps;
  averageRating: AverageRatingProps;
  textComments: TextCommentProps;
  locationLeaderboard: LocationLeaderboardProps;
  employeeLeaderboard: EmployeeLeaderboardProps;
}

export const getReportData = async (businessUid: string, startDate: Moment, endDate: Moment) => {
  // tslint:disable-next-line: max-line-length
  const url = `${
    Environment.BASE_URL
  }/businesses/${businessUid}/generateReport?startDate=${startDate.format(
    'MMM DD YYYY',
  )}&&endDate=${endDate.format('MMM DD YYYY')}`;
  const report: Report = await fetch(url).then(response => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Something went wrong');
  });
  return report;
};
