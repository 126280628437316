import React, { HTMLAttributes } from 'react';
import { Colors } from '../../theme';

interface SectionTitleProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  section: number;
}

const pad = (d: number) => {
  return d < 10 ? `0${d.toString()}` : d.toString();
};

export const SectionTitle: React.SFC<SectionTitleProps> = props => {
  const { title, section } = props;
  return (
    <div
      {...props}
      style={{
        ...props.style,
        fontFamily: 'Lato',
        textTransform: 'uppercase',
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'row',
        color: Colors.Blue,
        fontSize: 12,
        alignItems: 'center',
      }}>
      <div>{pad(section)}</div>
      <div
        style={{
          width: 100,
          height: 1.5,
          backgroundColor: Colors.Blue,
          marginLeft: 16,
          marginRight: 16,
        }}
      />
      <div>{title}</div>
    </div>
  );
};
