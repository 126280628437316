import React, { useEffect } from 'react';
import { RootState, getReviewDetails, deleteReview } from '../../redux';
import { connect } from 'react-redux';
import {
  ReviewType,
  ReviewNote,
  ReviewNoteType,
  BusinessProfileType,
  EmployeeProfileType,
} from '../../repos';
import { startCase } from 'lodash';
import { ReviewCard } from '../ReviewCard';
import { SectionListData } from '../../utils';
import { ContainerLoading } from '..';
import { Colors } from '../../theme';
import { Empty, Button } from 'antd';

interface ReviewDetailsProps {
  review: ReviewType;
  businessProfile?: BusinessProfileType;
  employeeProfile?: EmployeeProfileType;
  loading?: boolean;
  reviewNotes?: SectionListData<ReviewNote>[];
  onDelete?: () => void;
  getReviewDetails: typeof getReviewDetails;
  deleteReview: typeof deleteReview;
}

// tslint:disable-next-line: variable-name
const ReviewDetailsContainer: React.SFC<ReviewDetailsProps> = props => {
  const {
    getReviewDetails,
    deleteReview,
    onDelete,
    review,
    loading,
    reviewNotes = [],
    businessProfile,
    employeeProfile,
  } = props;

  useEffect(() => {
    getReviewDetails(review.uid);
  }, [review.uid]);

  // Render methods

  const renderNotes = () => {
    if (loading) {
      return <ContainerLoading />;
    }

    if (reviewNotes.length === 0) {
      return <Empty description="There are no notes at the moment" />;
    }

    return reviewNotes.map(section => {
      const { title, data } = section;
      return (
        <div key={title} style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              fontSize: 10,
              alignSelf: 'center',
              padding: '3px 8px',
              backgroundColor: Colors.White300,
              color: Colors.Steel300,
              borderRadius: 3,
              marginTop: 10,
              marginBottom: 8,
              fontWeight: 600,
            }}>
            {title}
          </div>
          {data.map(reviewNote => {
            const { description, createdAt, employeeName, type, id } = reviewNote;

            if (type === ReviewNoteType.System) {
              return (
                <div
                  key={id}
                  style={{
                    padding: '2px 6px',
                    alignSelf: 'center',
                    borderRadius: 3,
                    fontSize: 9,
                    backgroundColor: Colors.White200,
                    color: Colors.Steel200,
                    margin: '8px 0',
                  }}>
                  {description}
                </div>
              );
            }

            return (
              <div key={id} style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{
                    fontWeight: 500,
                  }}>
                  {employeeName}
                </div>
                <div
                  style={{
                    marginTop: 8,
                    padding: 8,
                    borderRadius: 4,
                    backgroundColor: Colors.Grey200,
                  }}>
                  {description}
                </div>
                <div
                  style={{
                    alignSelf: 'flex-end',
                    fontSize: 11,
                    color: Colors.Steel200,
                  }}>
                  {createdAt.format('hh:mm a')}
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  };

  const renderDeleteReview = () => {
    if (employeeProfile && !employeeProfile.featureOptIn.deleteReview) {
      return null;
    }
    return (
      <div>
        <div
          style={{
            fontSize: 13,
            fontWeight: 600,
            backgroundColor: Colors.Grey200,
            padding: '3px 16px',
            borderWidth: '0 1px 1px 1px',
            borderStyle: 'solid',
            borderColor: Colors.White200,
          }}>
          User Responses
        </div>
        <div
          style={{
            padding: '8px 8px',
          }}>
          {review.userResponses &&
            Object.values(review.userResponses).map(item => {
              return (
                <>
                  <strong>{startCase(item.answerDisplayName)}: </strong>
                  <span>{item.rawValue}</span>
                  <br />
                </>
              );
            })}
          <Button
            type="danger"
            style={{ margin: '8px 0' }}
            onClick={() => {
              deleteReview(review.uid);
              onDelete && onDelete();
            }}>
            Delete
          </Button>
        </div>
      </div>
    );
  };

  if (!businessProfile) {
    return <Empty description="Business profile hasn't loaded yet." />;
  }

  if (!employeeProfile) {
    return <Empty description="Employee profile hasn't loaded yet." />;
  }

  return (
    <div style={{ padding: 16 }}>
      <ReviewCard stages={businessProfile.resolutionStages} review={review} />
      {renderDeleteReview()}
      <div
        style={{
          fontSize: 13,
          fontWeight: 600,
          backgroundColor: Colors.Grey200,
          padding: '3px 16px',
          borderWidth: '0 1px 1px 1px',
          borderStyle: 'solid',
          borderColor: Colors.White200,
        }}>
        Remarks
      </div>
      <div
        style={{
          maxHeight: '40vh',
          overflow: 'scroll',
          padding: '8px 8px',
        }}>
        {renderNotes()}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { businessProfile, employeeProfile } = state.BusinessProfile;
  const { reviewNotes, loading } = state.ReviewDetail;
  return { reviewNotes, loading, businessProfile, employeeProfile };
};

export const ReviewDetails = connect(
  mapStateToProps,
  { getReviewDetails, deleteReview },
)(ReviewDetailsContainer);
