import { firestore } from 'firebase';

export enum FirestoreCollection {
  Reviews = 'reviews',
  BusinessProfiles = 'businessProfiles',
  BusinessProfileCustomers = 'customers',
  Stats = 'stats',
  FcmTokens = 'fcmTokens',
  BusinessEmployees = 'businessEmployees',
  ReviewNotes = 'reviewNotes',
  FeatureOptIn = 'featureOptIn',
}

export const FirestoreReference = {
  BusinessProfiles: () => firestore().collection(FirestoreCollection.BusinessProfiles),
  BusinessEmployees: () => firestore().collection(FirestoreCollection.BusinessEmployees),
  Reviews: () => firestore().collection(FirestoreCollection.Reviews),
  Stats: () => firestore().collection(FirestoreCollection.Stats),
  FcmTokens: () => firestore().collection(FirestoreCollection.FcmTokens),
  FeatureOptIn: () => firestore().collection(FirestoreCollection.FeatureOptIn),
  ReviewNotes: (reviewUid: string) =>
    firestore()
      .collection(FirestoreCollection.Reviews)
      .doc(reviewUid)
      .collection(FirestoreCollection.ReviewNotes),
};
