import { SectionListData } from '../../utils';
import { ReviewType } from '../../repos';
import { action, ActionType } from 'typesafe-actions';
import { Moment } from 'moment-timezone';

export enum HomeActionTypes {
  GetUnhappyCustomerReviews = 'Home/GetUnhappyCustomerReviews',
  SetUnhappyCustomerReviews = 'Home/SetUnhappyCustomerReviews',
  GetHappyCustomerReviews = 'Home/GetHappyCustomerReviews',
  SetHappyCustomerReviews = 'Home/SetHappyCustomerReviews',
  GetNeutralCustomerReviews = 'Home/GetNeutralCustomerReviews',
  SetNeutralCustomerReviews = 'Home/SetNeutralCustomerReviews',
  GetAverageTurnaroundTime = 'Home/GetAverageTurnaroundTime',
  SetAverageTurnaroundTime = 'Home/SetAverageTurnaroundTime',
}

export const getUnhappyCustomerReviews = (startDate: Moment, endDate: Moment) =>
  action(HomeActionTypes.GetUnhappyCustomerReviews, { startDate, endDate });

export const setUnhappyCustomerReviews = (reviews: {
  resolved: SectionListData<ReviewType>[];
  unresolved: SectionListData<ReviewType>[];
}) => action(HomeActionTypes.SetUnhappyCustomerReviews, { reviews });

export const getHappyCustomerReviews = (startDate: Moment, endDate: Moment) =>
  action(HomeActionTypes.GetHappyCustomerReviews, { startDate, endDate });

export const setHappyCustomerReviews = (reviews: SectionListData<ReviewType>[]) =>
  action(HomeActionTypes.SetHappyCustomerReviews, { reviews });

export const getNeutralCustomerReviews = (startDate: Moment, endDate: Moment) =>
  action(HomeActionTypes.GetNeutralCustomerReviews, { startDate, endDate });

export const setNeutralCustomerReviews = (reviews: SectionListData<ReviewType>[]) =>
  action(HomeActionTypes.SetNeutralCustomerReviews, { reviews });

export const getAverageTurnaroundTime = (startDate: Moment, endDate: Moment) =>
  action(HomeActionTypes.GetAverageTurnaroundTime, { startDate, endDate });

export const setAverageTurnaroundTime = (averageTurnaroundTime: {
  averageResolutionTime: number;
  averageResponseTime: number;
}) => action(HomeActionTypes.SetAverageTurnaroundTime, { averageTurnaroundTime });

const homeActions = {
  getUnhappyCustomerReviews,
  setUnhappyCustomerReviews,
  getHappyCustomerReviews,
  setHappyCustomerReviews,
  getNeutralCustomerReviews,
  setNeutralCustomerReviews,
  getAverageTurnaroundTime,
  setAverageTurnaroundTime,
};

export type HomeAction = ActionType<typeof homeActions>;
