import { combineReducers } from 'redux';
import { AuthReducer, AuthStateType } from './AuthReducer';
import { CommonReducer, CommonStateType } from './CommonReducer';
import { BusinessProfileReducer, BusinessProfileStateType } from './BusinessProfileReducer';
import { HomeReducer, HomeStateType } from './HomeReducer';
import { ReviewDetailReducer, ReviewDetailStateType } from './ReviewDetailReducer';
import { ReportReducer, ReportStateType } from './ReportReducer';

export const rootReducer = combineReducers({
  Auth: AuthReducer,
  Common: CommonReducer,
  BusinessProfile: BusinessProfileReducer,
  Home: HomeReducer,
  ReviewDetail: ReviewDetailReducer,
  Report: ReportReducer,
});

export interface RootState {
  Auth: AuthStateType;
  Common: CommonStateType;
  BusinessProfile: BusinessProfileStateType;
  Home: HomeStateType;
  ReviewDetail: ReviewDetailStateType;
  Report: ReportStateType;
}
