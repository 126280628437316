import React, { HTMLAttributes } from 'react';
import { Colors } from '../../theme';

interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  page: number;
}

const pad = (d: number) => {
  return d < 10 ? `0${d.toString()}` : d.toString();
};

export const Footer: React.SFC<FooterProps> = props => {
  const { page } = props;
  return (
    <div
      {...props}
      style={{
        alignItems: 'center',
        alignSelf: 'stretch',
        paddingTop: 24,
        display: 'flex',
        flexDirection: 'row',
        ...props.style,
      }}>
      <div style={{ fontSize: 12, color: Colors.TextDarker, marginTop: 32, opacity: 0.5 }}>
        PULSE CX REPORT
      </div>
      <div
        style={{
          marginLeft: 'auto',
          opacity: 0.05,
          fontWeight: 'bold',
          fontSize: 86,
          color: Colors.Text,
        }}>
        {pad(page)}
      </div>
    </div>
  );
};
