import React from 'react';
import { SectionTitle, Footer, Dots } from '../../../components';
import { Colors } from '../../../theme';
import { BarChart, YAxis, XAxis, Bar, Cell, CartesianGrid, ResponsiveContainer } from 'recharts';
import { AverageRatingProps } from '../../../repos';

export const AverageRating: React.SFC<AverageRatingProps> = props => {
  const { ratingData, weeklyTrend } = props;

  const averageRatingData = ratingData.map(item => {
    const color =
      item.rating <= 3 ? Colors.Red : item.rating === 4 ? Colors.Yellow : Colors.LightGreen;
    return {
      color,
      name: item.rating,
      count: item.count,
    };
  });

  const weeklyTrendData = weeklyTrend.map(item => ({
    name: `Week ${item.week}`,
    value: item.value,
  }));

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
        alignItems: 'stretch',
        fontFamily: 'Lato',
      }}>
      <Dots
        style={{
          position: 'absolute',
          margin: 32,
        }}
        dotscolor={Colors.TextDarker}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '72px 48px 100px 48px',
          flex: 1,
          backgroundColor: Colors.BackgroundGrey,
        }}>
        <SectionTitle section={3} title="Average Rating" />
        <div
          style={{
            fontSize: 18,
            marginTop: 24,
            marginBottom: 24,
            fontWeight: 700,
            color: Colors.TextDarker,
            textTransform: 'uppercase',
          }}>
          Average Rating
        </div>
        <div style={{ fontSize: 14, lineHeight: 1.6, marginBottom: 16, opacity: 0.8 }}>
          <p>
            <b>Average Rating</b> is the sum of all the ratings given by the customers divided by
            the number of customers who rated.
          </p>
        </div>
        <ResponsiveContainer width={'100%'} height={280}>
          <BarChart
            height={280}
            width={500}
            margin={{ left: -32, right: 48 }}
            layout="vertical"
            barGap={32}
            data={averageRatingData}>
            <CartesianGrid strokeOpacity={0.5} horizontal={false} strokeDasharray="3 3" />
            <YAxis
              tickLine={false}
              stroke={'#d8d8d8'}
              type="category"
              tickMargin={8}
              tick={{ fill: Colors.TextDarker, fontSize: 13 }}
              dataKey="name"
            />
            <XAxis
              padding={{ left: 8, right: 0 }}
              type="number"
              stroke={'#d8d8d8'}
              tickMargin={8}
              tick={{ fill: Colors.TextDarker, fontSize: 13 }}
              dataKey="count"
              tickLine={false}
            />
            <Bar
              isAnimationActive={false}
              // radius={[2, 12, 12, 2]}
              x={16}
              barSize={24}
              minPointSize={2}
              height={20}
              label={{
                position: 'right',
                fill: 'rgb(58, 75, 120)',
                fontSize: 15,
                fontWeight: 600,
                offset: 8,
              }}
              dataKey="count">
              {averageRatingData.map(item => {
                return <Cell key={item.name} fill={item.color} />;
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '72px 48px 100px 48px',
          flex: 1,
          backgroundColor: 'white',
        }}>
        <SectionTitle section={4} title="Average Rating" />
        <div
          style={{
            fontSize: 18,
            marginTop: 24,
            marginBottom: 24,
            fontWeight: 700,
            color: Colors.TextDarker,
            textTransform: 'uppercase',
          }}>
          Weekly Trend - Average Rating
        </div>
        <div style={{ fontSize: 14, lineHeight: 1.6, marginBottom: 16, opacity: 0.8 }}>
          <p>Average Rating score trend of your business in the previous 4 weeks.</p>
        </div>
        <ResponsiveContainer width={'100%'} height={355}>
          <BarChart
            margin={{ left: -32, right: 24 }}
            layout="horizontal"
            barGap={32}
            data={weeklyTrendData}>
            <defs>
              <linearGradient id="primaryGradientVertical" x1={0} x2={0} y1={0} y2={1}>
                <stop offset="0%" stopColor="#512dcf" />
                <stop offset="100%" stopColor="#2a88f1" />
              </linearGradient>
            </defs>
            <CartesianGrid strokeOpacity={0.5} vertical={false} />
            <YAxis
              tickLine={false}
              axisLine={false}
              ticks={[1, 2, 3, 4, 5]}
              domain={[1, 5]}
              tickCount={5}
              type="number"
              tickMargin={8}
              tick={{ fill: Colors.TextDarker, fontSize: 13 }}
              dataKey="value"
            />
            <XAxis
              padding={{ left: 8, right: 0 }}
              type="category"
              stroke={'#d8d8d8'}
              tickMargin={8}
              tick={{ fill: Colors.TextDarker, fontSize: 13 }}
              dataKey="name"
              tickLine={false}
            />
            <Bar
              isAnimationActive={false}
              x={16}
              barSize={48}
              minPointSize={2}
              height={20}
              fill={'url(#primaryGradientVertical)'}
              label={{
                position: 'insideTop',
                fill: 'white',
                fontSize: 13,
                fontWeight: 600,
                offset: 10,
              }}
              dataKey="value"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <Footer
        style={{
          position: 'absolute',
          width: 'calc(100% - 64px)',
          alignSelf: 'flex-end',
          marginLeft: 48,
        }}
        page={2}
      />
    </div>
  );
};
