import React, { HTMLAttributes } from 'react';

interface DotsProps extends HTMLAttributes<HTMLDivElement> {
  dotscolor?: string;
}

export const Dots: React.SFC<DotsProps> = props => {
  const { dotscolor = '#FFF' } = props;
  return (
    <div
      {...props}
      style={{ ...props.style, alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
      <div
        style={{
          backgroundColor: dotscolor,
          height: 10,
          width: 10,
          borderRadius: 5,
        }}
      />
      <div
        style={{
          backgroundColor: dotscolor,
          height: 8,
          width: 8,
          borderRadius: 5,
          marginLeft: 8,
        }}
      />
      <div
        style={{
          backgroundColor: dotscolor,
          height: 4,
          width: 4,
          borderRadius: 5,
          marginLeft: 8,
        }}
      />
    </div>
  );
};
