import React from 'react';
import { Router } from '@reach/router';
import { RootState, listenToAuthState } from './redux';
import { connect } from 'react-redux';
import { Navbar } from './components';
import { User } from 'firebase';

import {
  SignInContainer,
  LoadingContainer,
  UnhappyCustomersContainer,
  HappyCustomersContainer,
  NeutralCustomersContainer,
} from './containers';
import { message } from 'antd';
import Reports from './containers/Reports/Reports';
import { EmployeeProfileType } from './repos';

// Only use kebab-case route names.
export enum Routes {
  Home = '/',
  UnhappyCustomers = 'unhappy-customers',
  HappyCustomers = 'happy-customers',
  NeutralCustomers = 'neutral-customers',
  SignIn = 'sign-in',
  SignUp = 'sign-up',
  Reports = 'reports',
}

interface AppProps {
  currentUser?: User | null;
  employeeProfile?: EmployeeProfileType;
  listingName?: string;
  listenToAuthState: typeof listenToAuthState;
  error?: string;
}

class App extends React.Component<AppProps> {
  componentDidMount() {
    this.props.listenToAuthState();
  }

  componentWillReceiveProps(nextProps: AppProps) {
    if (this.props.error !== nextProps.error && nextProps.error) {
      message.error(nextProps.error);
    }
  }

  render() {
    const { employeeProfile, currentUser, listingName } = this.props;
    if (currentUser === undefined) {
      return <LoadingContainer />;
    }

    if (currentUser === null) {
      return (
        <Router>
          <SignInContainer path={Routes.SignIn} default />
        </Router>
      );
    }

    return (
      <div>
        <Navbar employeeName={employeeProfile && employeeProfile.name} listingName={listingName} />
        <Router style={{ paddingTop: 120 }}>
          <UnhappyCustomersContainer default path={Routes.UnhappyCustomers} />
          <HappyCustomersContainer path={Routes.HappyCustomers} />
          <NeutralCustomersContainer path={Routes.NeutralCustomers} />
          {employeeProfile && employeeProfile.featureOptIn.showReportsInReputationWeb && (
            <Reports path={Routes.Reports} />
          )}
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): Partial<AppProps> => {
  const { currentUser } = state.Auth;
  const { error } = state.Common;
  const { employeeProfile, employeeListings, currentListingUid } = state.BusinessProfile;

  return {
    error,
    currentUser,
    employeeProfile,
    listingName: employeeListings && currentListingUid && employeeListings[currentListingUid],
  };
};

export default connect(
  mapStateToProps,
  { listenToAuthState },
)(App);
