import { RootEpic } from '.';
import { filter, switchMap, map, catchError, withLatestFrom, mapTo } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import {
  setReviewDetails,
  setReviewDetailsError,
  setReviewDetailsMessage,
  ReviewDetailActionTypes,
} from '../actions';
import { ReviewRepo } from '../../repos';
import { of, Observable } from 'rxjs';
import { RootAction } from '..';
import { groupBy } from 'lodash';
import moment from 'moment';
import { ReviewNote } from '../../repos/Review';
import { SectionListData } from '../../utils';

export const listenToReviewDetailsEpic: RootEpic = action$ => {
  return action$.pipe(
    filter(isOfType(ReviewDetailActionTypes.GetReviewDetails)),
    switchMap(action => {
      const { reviewUid } = action.payload;
      return ReviewRepo.listenToReviewNotes(reviewUid).pipe(
        map(reviewNotes => {
          const grouped = groupBy(reviewNotes, reviewNote => {
            if (reviewNote.createdAt.isSame(moment(), 'day')) {
              return 'Today';
            }
            return reviewNote.createdAt.format('HH MMMM YYYY');
          });
          const sections = Object.entries(grouped).map(
            ([title, reviewNotes]): SectionListData<ReviewNote> => {
              return {
                title,
                data: reviewNotes,
              };
            },
          );
          return setReviewDetails(sections);
        }),
        catchError(error => of(setReviewDetailsError(error.message))),
      );
    }),
  );
};

export const markReviewAsEpic: RootEpic = (action$, store) => {
  return action$.pipe(
    filter(isOfType(ReviewDetailActionTypes.MarkReviewAs)),
    withLatestFrom(store),
    switchMap(
      ([action, store]): Observable<RootAction> => {
        const { reviewUid, stage } = action.payload;
        const { employeeProfile } = store.BusinessProfile;
        if (!employeeProfile) {
          return of(setReviewDetailsError('Please sign in again'));
        }
        return ReviewRepo.markReviewAs(
          reviewUid,
          stage,
          employeeProfile.employeeUid,
          employeeProfile.name,
        ).pipe(
          mapTo(setReviewDetailsMessage('Review marked as resolved')),
          catchError(error => of(setReviewDetailsError(error.message))),
        );
      },
    ),
  );
};

export const deleteReviewEpic: RootEpic = (action$, store) => {
  return action$.pipe(
    filter(isOfType(ReviewDetailActionTypes.DeleteReview)),
    withLatestFrom(store),
    switchMap(
      ([action, store]): Observable<RootAction> => {
        const { reviewUid } = action.payload;
        return ReviewRepo.deleteReview(reviewUid).pipe(
          mapTo(setReviewDetailsMessage('Review deleted')),
          catchError(error => of(setReviewDetailsError(error.message))),
        );
      },
    ),
  );
};

export const addReviewNoteEpic: RootEpic = (action$, store) => {
  return action$.pipe(
    filter(isOfType(ReviewDetailActionTypes.AddNoteToReview)),
    withLatestFrom(store),
    switchMap(
      ([action, store]): Observable<RootAction> => {
        const { reviewUid, description } = action.payload;
        const { employeeProfile } = store.BusinessProfile;
        if (!employeeProfile) {
          return of(setReviewDetailsError('Please sign in again'));
        }
        return ReviewRepo.addReviewNote(
          reviewUid,
          description,
          employeeProfile.employeeUid,
          employeeProfile.name,
        ).pipe(
          mapTo(setReviewDetailsMessage('You note was added successfully')),
          catchError(error => of(setReviewDetailsError(error.message))),
        );
      },
    ),
  );
};
